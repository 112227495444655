export default function BigLoader({ progress }) {
  return (
    <div className="flex fixed top-0 left-0 w-full h-screen justify-center items-center bg-black-100 bg-opacity-50 z-50">
      {progress && (
        <>
          <label
            style={{
              zIndex: 1,
              position: "absolute",
              top: "48.5%",
              color: "white",
            }}
          >
            {progress === 100 ? "finishing.." : `${progress}%`}
          </label>
        </>
      )}
      <div className="lds-dual-ring"></div>
    </div>
  );
}
