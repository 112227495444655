import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Card from "../../../components/Card";
import Loader from "../../../components/Loader";
import SubjectList from "../../../components/SubjectList";
import { setInterface } from "../../../redux/slices/interfaceSlice";
import service from "../../../service";
import swal from "@sweetalert/with-react";
import BigLoader from "../../../components/BigLoader";
import Button from "../../../components/Button";

export default function Subject() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [postLoading, setPostLoading] = useState(false);
  const [saveArranged, setSaveArranged] = useState(false);

  const arrange = (childs, changed, direction) => {
    const toSwap = direction === "up" ? changed - 1 : changed + 1;
    childs[toSwap] = childs.splice(changed, 1, childs[toSwap])[0];
    return childs;
  };

  const swap = (childs, index, changed, direction) => {
    if (index.length > 0) {
      const subjectList = childs.map((item, i) => {
        if (i === index[0]) {
          index.splice(0, 1);
          return {
            ...item,
            childs: swap(item.childs, index, changed, direction),
          };
        } else {
          return item;
        }
      });

      return subjectList;
    }

    return arrange(childs, changed, direction);
  };

  const changePosition = (direction, ...index) => {
    const indexChanged = index.pop();

    const swapped = swap(subjects, index, indexChanged, direction);
    setSubjects([...swapped]);
    setSaveArranged(true);
  };

  const getPosition = (childs) => {
    let position = [];
    let index = 0;
    for (let child of childs) {
      position.push({
        id: child.id,
        position: index,
      });
      if (child.childs.length) {
        const getChild = getPosition(child.childs);
        position = position.concat(getChild);
      }
      index = index + 1;
    }
    return position;
  };

  const saveArrangedSubject = () => {
    const data = getPosition(subjects);

    setPostLoading(true);
    service
      .put("/api/subject/arrange", {
        positions: data,
      })
      .then(() => {
        setPostLoading(false);
        getData();
        swal("Berhasil", `Materi telah diurutkan`, "success");
      })
      .catch(() => {
        setPostLoading(false);
      });
  };

  const onDelete = (id) => {
    swal({
      title: "Anda yakin?",
      text: "Anda akan menghapus materi ini",
      icon: "warning",
      buttons: ["Batal", true],
      dangerMode: true,
      cancelButton: "Batal",
    }).then((value) => {
      if (value) {
        setPostLoading(true);
        service
          .delete("/api/subject/" + id)
          .then(() => {
            setPostLoading(false);
            getData();
            swal("Berhasil", `Materi telah dihapus`, "success");
          })
          .catch(() => {
            setPostLoading(false);
          });
      }
    });
  };

  const getData = () => {
    setLoading(true);
    service
      .get(`/api/course/${id}`)
      .then((response) => {
        setLoading(false);
        setData(response.data);
        setSubjects(response.data.subjects);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(setInterface({ active: "Online Course", pageTitle: "Materi" }));
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <div className="h-64 flex justify-center items-center flex-col">
        <Helmet>
          <title>Materi</title>
        </Helmet>
        <Loader className="bg-black-100" />
        <span className="mt-3">Memuat</span>
      </div>
    );
  }
  return (
    <Fragment>
      <Helmet>
        <title>Materi</title>
      </Helmet>
      <Card>
        <div className="flex flex-col items-center">
          <img
            src={process.env.REACT_APP_BASE_URL + data.cover}
            className="w-32 h-32 object-cover"
            alt="Cover"
          />
          <div className="flex-1 mt-5">
            <h1 className="font-bold text-base lg:text-lg text-center">
              {data.title}
            </h1>
            <div className="flex flex-wrap mt-1 justify-center text-sm">
              {data.users ? data.users[0]?.fullname : "Belum Ada"}
            </div>
          </div>
        </div>
      </Card>
      <Card className="mt-5">
        <div className="flex items-center mb-8">
          <h3 className="text-lg lg:text-xl font-bold monda flex-1">
            Daftar Materi
          </h3>
          {saveArranged ? (
            <div>
              <button
                type="button"
                onClick={() => {
                  setSaveArranged(false);
                  setSubjects(data.subjects);
                }}
                className="px-5"
              >
                Batal
              </button>
              <Button type="button" onClick={() => saveArrangedSubject()}>
                Simpan
              </Button>
            </div>
          ) : (
            <Link
              className="font-bold text-primary-500"
              to={`/course/${id}/subject/add`}
            >
              + Tambah Baru
            </Link>
          )}
        </div>
        {subjects?.length > 0 ? (
          <ul>
            {subjects?.map((item, index) => (
              <SubjectList
                parent={id}
                title={item.title}
                key={`${index}`}
                id={item.id}
                indexs={[index]}
                showUp={index > 0}
                showDown={index < subjects.length - 1}
                onUp={(...index) => changePosition("up", ...index)}
                onDown={(...index) => changePosition("down", ...index)}
                childrens={item.childs}
                onDelete={(id) => onDelete(id)}
              />
            ))}
          </ul>
        ) : (
          <div className="text-center flex flex-col justify-center items-center h-40">
            <p>Belum ada materi</p>
          </div>
        )}
      </Card>
      {postLoading ? <BigLoader /> : null}
    </Fragment>
  );
}
