import moment from "moment";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DateInput({
  containerClassName,
  className,
  label,
  message,
  left,
  ...props
}) {
  return (
    <div className={containerClassName}>
      {label && <label className="text-black-200 font-bold">{label}</label>}
      <div className="h-12 border border-white-300 w-full flex items-center rounded-sm">
        {left && <div className="ml-3 mr-1 text-black-400">{left}</div>}
        <DatePicker
          dateFormat="dd/MM/yyyy"
          className="h-full p-2 rounded-sm focus:outline-none w-full"
          {...props}
        />
      </div>
      {message && <span className="text-red-200 text-sm">{message}</span>}
    </div>
  );
}
