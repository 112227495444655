import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../components/Button";
import Card from "../../components/Card";
import TextInput from "../../components/TextInput";
import { setInterface } from "../../redux/slices/interfaceSlice";
import Dropzone from "../../components/Dropzone";
import swal from "@sweetalert/with-react";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import BigLoader from "../../components/BigLoader";
import service from "../../service";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { Helmet } from "react-helmet-async";
import Editor from "../../components/Editor";
import CreatableSelect from "react-select/creatable";
import ReactAudioPlayer from "react-audio-player";

function parseValue(values) {
  const result = [];
  for (let value of values) {
    result.push({
      value: value.id,
      label: value.name,
    });
  }

  return result;
}

export default function ComposeContent({ editMode = false }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [authorOptions, setAuthorOptions] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      categories: [],
      createCategories: [],
      authors: [],
      createAuthors: [],
    },
  });
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState(editMode);
  const [categorySelected, setCategorySelected] = useState([]);
  const [authorSelected, setAuthorSelected] = useState([]);

  const _proceed = ({
    title,
    cover,
    synopsis,
    categories = [],
    createCategories = [],
    authors = [],
    createAuthors = [],
    audio,
  }) => {
    setLoading(true);

    const formData = new FormData();

    formData.append("title", title);
    formData.append("synopsis", synopsis);
    for (const createdCategory of createCategories) {
      formData.append("createCategories[]", createdCategory);
    }
    for (const selectedCategory of categories) {
      formData.append("categories[]", selectedCategory);
    }
    for (const createdAuthor of createAuthors) {
      formData.append("createAuthors[]", createdAuthor);
    }
    for (const selectedAuthor of authors) {
      formData.append("authors[]", selectedAuthor);
    }
    if (cover instanceof File) {
      formData.append("cover", cover);
    }
    if (audio instanceof File) {
      formData.append("audio", audio);
    }

    service({
      url: editMode ? "/api/content/" + id : "/api/content",
      data: formData,
      method: editMode ? "PUT" : "POST",
    })
      .then((response) => {
        const { id, cover: afterCover } = response.data;
        setLoading(false);
        swal(
          "Berhasil",
          editMode ? "Konten berhasil disunting" : "Konten telah ditambahkan",
          "success"
        ).then((value) => {
          if (value && !editMode) {
            history.replace("/content/" + id + "/bab");
          } else if (cover) {
            setValue("cover", afterCover);
          }
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(
      setInterface({
        active: "Konten",
        pageTitle: editMode ? "Edit Konten" : "Tambah Konten",
      })
    );
  }, [dispatch, editMode]);

  useEffect(() => {
    if (editMode) {
      service
        .get("/api/content/" + id)
        .then((response) => {
          const { title, synopsis, cover, categories, audio, authors } =
            response.data;
          setLoadingContent(false);
          const categoriesSelected = [];
          const authorsSelected = [];
          for (let category of categories) {
            categoriesSelected.push(category.id);
          }
          for (let author of authors) {
            authorsSelected.push(author.id);
          }
          setCategorySelected(parseValue(categories));
          setAuthorSelected(parseValue(authors));
          reset({
            title,
            synopsis,
            cover,
            categories: categoriesSelected,
            authors: authorsSelected,
            audio,
          });
        })
        .catch((e) => {
          setLoadingContent(false);
          if (e.response?.status === 404) {
            history.replace("/content");
          }
        });
    }
  }, [editMode, id]);

  useEffect(() => {
    service
      .get("/api/category")
      .then((response) => {
        const data = [];
        for (let category of response.data) {
          data.push({
            value: category.id,
            label: category.name,
          });
        }

        setCategoryOptions(data);
      })
      .catch((e) => {});
    service
      .get("/api/authors")
      .then((response) => {
        const data = [];
        for (let author of response.data) {
          data.push({
            value: author.id,
            label: author.name,
          });
        }

        setAuthorOptions(data);
      })
      .catch((e) => {});
  }, []);

  if (loadingContent) {
    return (
      <div className="h-64 flex justify-center items-center flex-col">
        <Loader className="bg-black-100" />
        <span className="mt-3">Memuat</span>
      </div>
    );
  }

  return (
    <form
      className="flex flex-col lg:flex-row"
      onSubmit={handleSubmit(_proceed)}
    >
      <Helmet>
        <title>{(editMode ? "Edit" : "Tambah") + " Konten"}</title>
      </Helmet>
      <Card className="flex-1">
        <TextInput
          type="text"
          label="Judul"
          containerClassName="mb-4"
          message={errors.title?.message}
          {...register("title", { required: "Tidak boleh kosong" })}
        />
        <div className="mb-4">
          <label className="text-black-200 font-bold">Author</label>
          <CreatableSelect
            isMulti
            value={authorSelected}
            styles={{
              control: (base) => ({
                ...base,
                border: 0,
                // This line disable the blue border
                boxShadow: "none",
              }),
            }}
            onChange={(inputValue) => {
              const newAuthors = [],
                authors = [];
              for (let value of inputValue) {
                if (value.__isNew__) {
                  newAuthors.push(value.value);
                } else {
                  authors.push(value.value);
                }
              }
              setValue("authors", authors);
              setValue("createAuthors", newAuthors);
              setAuthorSelected(inputValue);
            }}
            options={authorOptions}
            classNamePrefix="react-select"
            className="react-select-container mb-4"
          />
        </div>
        <div className="mb-4">
          <label className="text-black-200 font-bold">Kategori</label>
          <CreatableSelect
            isMulti
            value={categorySelected}
            styles={{
              control: (base) => ({
                ...base,
                border: 0,
                // This line disable the blue border
                boxShadow: "none",
              }),
            }}
            onChange={(inputValue) => {
              const newCategories = [],
                categories = [];
              for (let value of inputValue) {
                if (value.__isNew__) {
                  newCategories.push(value.value);
                } else {
                  categories.push(value.value);
                }
              }
              setValue("categories", categories);
              setValue("createCategories", newCategories);
              setCategorySelected(inputValue);
            }}
            options={categoryOptions}
            classNamePrefix="react-select"
            className="react-select-container mb-4"
          />
        </div>

        <Controller
          control={control}
          name="synopsis"
          defaultValue=""
          rules={{ required: "Tidak boleh kosong" }}
          render={({ field: { value, onChange } }) => (
            <Editor
              label="Plot"
              containerClassName="mb-4"
              value={value}
              message={errors.synopsis?.message}
              onChange={(text) => {
                onChange(text);
              }}
            />
          )}
        />

        <Button type="submit" className="w-24 hidden lg:block">
          {editMode ? "Edit" : "Tambah"}
        </Button>
      </Card>
      <div className="w-auto lg:w-5/12 ml-0 mt-5 lg:ml-5 lg:mt-0">
        <Card>
          <Controller
            control={control}
            name="cover"
            rules={{ required: "Tidak boleh kosong" }}
            render={({ field: { onChange } }) => (
              <Dropzone
                dropzone={{
                  accept: "image/jpeg, image/png",
                }}
                message={errors.cover?.message}
                label="Gambar Cover"
                onChange={(accepted) => {
                  onChange(accepted[0]);
                }}
                containerClassName="mb-4"
              />
            )}
          />
          {watch("cover") && (
            <Fragment>
              <span className="font-bold">Preview :</span>
              <div className="flex justify-center">
                <img
                  src={
                    watch("cover") instanceof File
                      ? URL.createObjectURL(watch("cover"))
                      : process.env.REACT_APP_BASE_URL + watch("cover")
                  }
                  alt="Preview Cover"
                  className="w-28 h-28 object-cover"
                />
              </div>
            </Fragment>
          )}
        </Card>
        <Card className="mt-5">
          <Controller
            control={control}
            name="audio"
            render={({ field: { onChange } }) => (
              <Dropzone
                label="File Audio Plot (opsional)"
                dropzone={{
                  accept: "audio/mpeg, audio/ogg, audio/wav, audio/aac",
                }}
                message={errors.audio?.message}
                containerClassName="mb-4"
                onChange={(accepted) => {
                  onChange(accepted[0]);
                }}
              />
            )}
          />

          {watch("audio") && (
            <Fragment>
              <span className="font-bold">Preview :</span>
              <div className="flex justify-center">
                <ReactAudioPlayer
                  className="w-full mt-2 mb-4"
                  src={
                    watch("audio") instanceof File
                      ? URL.createObjectURL(watch("audio"))
                      : process.env.REACT_APP_BASE_URL + watch("audio")
                  }
                  controls
                />
              </div>
            </Fragment>
          )}
        </Card>
      </div>
      <Card className="block lg:hidden mt-5">
        <Button type="submit" className="w-24">
          {editMode ? "Edit" : "Tambah"}
        </Button>
      </Card>
      {loading && <BigLoader />}
    </form>
  );
}
