import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import Card from "../../components/Card";
import DateInput from "../../components/DateInput";
import Loader from "../../components/Loader";
import Table, { Td, Th } from "../../components/Table";
import useDebounce from "../../debounce";
import { setInterface } from "../../redux/slices/interfaceSlice";
import service from "../../service";

export default function TopCategory() {
  const [start, setStart] = useState(moment().subtract(7, "days").toDate());
  const [end, setEnd] = useState(moment().toDate());
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const _first = useRef(true);

  const debounce = useDebounce((value) =>
    getData(value[0], value[1], value[2])
  );

  useEffect(() => {
    dispatch(setInterface({ active: "Statistik/Buku", pageTitle: "Buku" }));
  }, [dispatch]);

  const getData = (start, end, page) => {
    service
      .get("/api/stat/most-read-category", {
        params: {
          start: moment(start).format("YYYY-MM-DD"),
          end: moment(end).format("YYYY-MM-DD"),
          per_page: 100,
          page,
        },
      })
      .then((response) => {
        _first.current = false;
        setLoading(false);
        setData(response.data.data);
        setTotal(response.data.total);
      })
      .catch(() => {
        setLoading(false);
        setData([]);
      });
  };

  const _adjust = (date, adjusting) => {
    if (date > end && adjusting === "start") {
      setEnd(moment(date).add(1, "day").toDate());
    }
    if (date < start && adjusting === "end") {
      setStart(moment(date).subtract(1, "day").toDate());
    }
  };

  useEffect(() => {
    if (!_first.current) {
      setLoading(true);
      debounce([start, end, page]);
    }
  }, [start, end]);

  useEffect(() => {
    setLoading(true);
    getData(start, end, page);
  }, [page]);

  return (
    <Fragment>
      <Helmet>
        <title>Buku</title>
      </Helmet>
      <Card>
        <div className="w-full md:w-1/2 lg:w-1/3 flex items-center">
          <DateInput
            selected={start}
            onChange={(date) => {
              const value = moment(date).toDate();
              setStart(value);
              _adjust(value, "start");
            }}
          />
          <span className="mx-2">-</span>
          <DateInput
            selected={end}
            onChange={(date) => {
              const value = moment(date).toDate();
              setEnd(value);
              _adjust(value, "end");
            }}
          />
        </div>
        <div className="border-t border-white-300 pt-3 mt-3">
          {loading ? (
            <div className="h-64 flex justify-center items-center flex-col">
              <Loader className="bg-black-100" />
              <span className="mt-3">Memuat</span>
            </div>
          ) : (
            <Fragment>
              <Table>
                <thead>
                  <tr>
                    <Th className="w-20">NO</Th>
                    <Th>JUDUL</Th>
                    <Th>KATEGORI</Th>
                    <Th className="w-1/3">JUMLAH BACA</Th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={`act-${index}`}>
                      <Td className="text-center">{index + 1}</Td>
                      <Td>{item.title}</Td>
                      <Td>
                        {item.categories?.length
                          ? item.categories.length > 1
                            ? `${item.categories[0].name} dan ${
                                Number(item.categories.length) - 1
                              } lainnya`
                            : item.categories[0].name
                          : "Tidak ada ketgori"}
                      </Td>
                      <Td className="text-center">{item.count}</Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <ReactPaginate
                pageCount={total}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                initialPage={page - 1}
                containerClassName="flex flex-wrap mt-8"
                previousLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
                nextLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
                pageLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
                activeLinkClassName="bg-primary-500 border-primary-600 text-white-100"
                onPageChange={({ selected }) => setPage(selected + 1)}
              />
            </Fragment>
          )}
        </div>
      </Card>
    </Fragment>
  );
}
