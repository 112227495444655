export default function Table({ children }) {
  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full border-collapse">{children}</table>
    </div>
  );
}

export const Th = ({ children, className }) => {
  return (
    <th
      className={`py-2 px-3 bg-black-300 text-white-100 border border-black-100 ${className}`}
    >
      {children}
    </th>
  );
};

export const Td = ({ children, className }) => {
  return (
    <td className={`py-2 px-3 border border-white-300 ${className}`}>
      {children}
    </td>
  );
};
