import moment from "moment";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { FiLock, FiUser } from "react-icons/fi";
import Alert from "../components/Alert";
import Button from "../components/Button";
import Loader from "../components/Loader";
import TextInput from "../components/TextInput";
import service from "../service";

export default function Login() {
  const { register, handleSubmit } = useForm();
  const setCookies = useCookies()[1];
  const [loading, setLoading] = useState(false);
  const [fail, setFail] = useState(false);

  const _login = ({ username, password }) => {
    setLoading(true);
    service
      .get("/api/auth/admin/get-token", {
        params: {
          username,
          password,
        },
      })
      .then((response) => {
        setFail(false);
        setLoading(false);
        const { id, username, token, role } = response.data;

        service.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        setCookies("id", id, {
          path: "/",
          expires: moment().add(10, "years").toDate(),
        });
        setCookies("username", username, {
          path: "/",
          expires: moment().add(10, "years").toDate(),
        });
        setCookies("token", token, {
          path: "/",
          expires: moment().add(10, "years").toDate(),
        });
        setCookies("role", role, {
          path: "/",
          expires: moment().add(10, "years").toDate(),
        });
      })
      .catch((e) => {
        setFail(true);
        setLoading(false);
      });
  };

  return (
    <div className="bg-background min-h-screen flex justify-center items-center">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="w-full lg:w-3/4 xl:w-3/5 flex rounded bg-white-100 h-96 m-8">
        <div className="hidden lg:block lg:w-1/2 xl:w-7/12"></div>
        <div className="flex-1 p-8 border-l border-white-200">
          <h1 className="montserrat-alternates text-black-100 font-bold text-2xl">
            Login
          </h1>
          <h2>Silahkan masuk ke akun anda</h2>
          <form className="mt-6" onSubmit={handleSubmit(_login)}>
            {fail ? (
              <Alert className="mb-3 text-center">Autentikasi gagal</Alert>
            ) : (
              <div className="h-10" />
            )}
            <TextInput
              {...register("username", { required: true })}
              left={<FiUser size={16} />}
              placeholder="Username"
              type="text"
              containerClassName="mb-3"
            />
            <TextInput
              {...register("password", { required: true })}
              left={<FiLock size={16} />}
              placeholder="Password"
              type="password"
              containerClassName="mb-3"
            />
            <Button type="submit" className="w-full">
              {loading ? <Loader className="bg-white-100" /> : "Masuk"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
