import { useState } from "react";
import {
  FiArrowDown,
  FiArrowUp,
  FiEdit2,
  FiMinus,
  FiPlus,
  FiTrash,
} from "react-icons/fi";
import { Link } from "react-router-dom";

export default function SubjectList({
  level = 0,
  title,
  childrens,
  id,
  onDelete = () => {},
  parent,
  indexs,
  showUp = false,
  showDown = false,
  onUp = () => {},
  onDown = () => {},
}) {
  const [show, setShow] = useState(false);
  return (
    <li className="flex mb-5 relative">
      {childrens && show ? (
        <div
          className="bg-white-300 absolute"
          style={{ left: 12, top: 24, bottom: -20, width: 1 }}
        />
      ) : null}
      <div>
        <button
          onClick={() => setShow((value) => !value)}
          type="button"
          className="mr-4 w-6 h-6 rounded-sm flex items-center justify-center bg-white-200"
        >
          {show ? <FiMinus /> : <FiPlus />}
        </button>
      </div>
      <div className="flex-1">
        <div className="relative">
          {level ? (
            <div
              className="border-t border-white-300 h-1 absolute"
              style={{ left: -68, top: 12, width: 28 }}
            />
          ) : null}
          <span className="font-bold">{title}</span>
          <div className="flex flex-wrap">
            <Link
              to={`/course/${parent}/subject/${id}/edit`}
              className="p-1 border border-white-300 text-sm hover:bg-yellow-200 hover:border-yellow-300 flex items-center mr-2 mt-1"
            >
              <FiEdit2 />
            </Link>
            <Link
              to={`/course/${parent}/subject/${id}/add`}
              className="p-1 border border-white-300 text-sm hover:bg-green-200 hover:border-green-300 hover:text-white-100 flex items-center mr-2 mt-1"
            >
              <FiPlus />
            </Link>
            <button
              type="button"
              onClick={() => {
                onDelete(id);
              }}
              className="p-1 border border-white-300 text-sm hover:bg-red-200 hover:border-red-300 hover:text-white-100 flex items-center mr-2 mt-1"
            >
              <FiTrash />
            </button>
            <button
              type="button"
              onClick={() => {
                if (showUp) {
                  onUp(...indexs);
                }
              }}
              className="p-1 border border-white-300 text-sm hover:bg-yellow-200 hover:border-yellow-300 hover:text-white-100 flex items-center mt-1"
            >
              <FiArrowUp />
            </button>
            <button
              type="button"
              onClick={() => {
                if (showDown) {
                  onDown(...indexs);
                }
              }}
              className="p-1 border border-l-0 border-white-300 text-sm hover:bg-yellow-200 hover:border-yellow-300 hover:text-white-100 flex items-center mr-2 mt-1"
            >
              <FiArrowDown />
            </button>
          </div>
        </div>
        {childrens ? (
          <ul className={`mt-5 ${show ? "block" : "hidden"}`}>
            {childrens?.map((item, index) => (
              <SubjectList
                id={item.id}
                title={item.title}
                key={`${index}`}
                indexs={[...indexs, index]}
                onUp={onUp}
                onDown={onDown}
                showUp={index > 0}
                showDown={index < childrens.length - 1}
                level={level + 1}
                childrens={item.childs || null}
                onDelete={onDelete}
                parent={parent}
              />
            ))}
          </ul>
        ) : null}
      </div>
    </li>
  );
}
