import moment from "moment";
import { FiBookOpen, FiClock } from "react-icons/fi";

export default function ReadBookList({ title, bab, imgSrc, createdTime }) {
  return (
    <div className="border-b border-white-200 mt-5 pb-5 flex">
      <img
        src={process.env.REACT_APP_BASE_URL + imgSrc}
        alt={"Cover " + title}
        className="w-20 lg:w-24 h-20 lg:h-24 object-cover"
      />
      <div className="flex-1 ml-5">
        <h3 className="font-bold text-base md:text-lg">{title}</h3>
        <div className="flex flex-wrap mt-1">
          <span className="flex items-start text-sm mr-5">
            <FiBookOpen className="mr-2 mt-1" />
            <span className="flex-1">{bab}</span>
          </span>
        </div>
        <div className="flex flex-wrap mt-1">
          <span className="flex items-center text-sm mr-5">
            <FiClock className="mr-2" />{" "}
            {moment(createdTime).format("DD/MM/YYYY HH:mm")}
          </span>
        </div>
      </div>
    </div>
  );
}
