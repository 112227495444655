import { forwardRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Editor = forwardRef(
  ({ containerClassName, toolbar, label, message, ...props }, ref) => {
    return (
      <div className={containerClassName}>
        {label && <label className="text-black-200 font-bold">{label}</label>}
        <ReactQuill
          theme="snow"
          {...props}
          ref={ref}
          modules={{
            toolbar: toolbar || [
              [{ font: [] }],
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["clean"],
            ],
          }}
        />
        {message && <span className="text-red-200 text-sm">{message}</span>}
      </div>
    );
  }
);

export default Editor;
