import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Dropzone from "../../components/Dropzone";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import Table, { Td, Th } from "../../components/Table";
import { setInterface } from "../../redux/slices/interfaceSlice";
import service from "../../service";

import swal from "@sweetalert/with-react";
import BigLoader from "../../components/BigLoader";

export default function Category() {
  const dispatch = useDispatch();
  const [getLoading, setGetLoading] = useState(false);
  const [data, setData] = useState([]);
  const { control, handleSubmit, watch } = useForm();
  const _iconModal = useRef();
  const [id, setId] = useState(0);
  const [postLoading, setPostLoading] = useState(false);

  const getData = () => {
    setGetLoading(true);
    service
      .get("/api/category")
      .then((response) => {
        setGetLoading(false);
        setData(response.data);
      })
      .catch((e) => {
        setGetLoading(false);
      });
  };

  const changeIcon = ({ icon }) => {
    setPostLoading(true);
    const formData = new FormData();
    formData.append("icon", icon);

    service
      .put(`/api/category/${id}`, formData)
      .then(() => {
        setPostLoading(false);
        getData();
        swal("Berhasil", "Icon berhasil diganti", "success").then((value) => {
          if (value) {
            _iconModal.current.hide();
          }
        });
      })
      .catch(() => {
        setPostLoading(false);
      });
  };

  useEffect(() => {
    dispatch(setInterface({ active: "Kategori", pageTitle: "Kategori" }));
    getData();
  }, [dispatch]);
  return (
    <Fragment>
      <Helmet>
        <title>Kategori</title>
      </Helmet>
      <Card>
        {getLoading ? (
          <div className="h-64 flex justify-center items-center flex-col">
            <Loader className="bg-black-100" />
            <span className="mt-3">Memuat</span>
          </div>
        ) : data.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <Th>NO</Th>
                <Th>NAMA</Th>
                <Th>KONTEN</Th>
                <Th>ICON</Th>
                <Th>AKSI</Th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>{item.name}</Td>
                  <Td className="text-center">{item.contents_count}</Td>
                  <Td>
                    <div className="flex justify-center">
                      {item.icon ? (
                        <img
                          src={process.env.REACT_APP_BASE_URL + item.icon}
                          className="w-8 h-8 object-contain"
                        />
                      ) : (
                        "Tidak ada icon"
                      )}
                    </div>
                  </Td>
                  <Td className="text-center">
                    <button
                      onClick={() => {
                        setId(item.id);
                        _iconModal.current.show();
                      }}
                      type="button"
                      className="py-2 px-3 border border-white-300 text-sm hover:bg-primary-500 hover:border-primary-600 hover:text-white-100"
                    >
                      Ganti Icon
                    </button>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="text-center flex flex-col justify-center items-center h-40">
            <p>Belum ada konten</p>
          </div>
        )}
      </Card>
      <Modal ref={_iconModal} title="Ganti Icon">
        <form onSubmit={handleSubmit(changeIcon)}>
          <Controller
            name="icon"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <Dropzone
                containerClassName="mb-4"
                onChange={(accepted) => {
                  onChange(accepted[0]);
                }}
                dropzone={{ accept: "image/jpeg, image/png" }}
              />
            )}
          />
          {watch("icon") ? (
            <Fragment>
              <span className="font-bold">Preview :</span>
              <div className="flex justify-center mb-4">
                <img
                  src={URL.createObjectURL(watch("icon"))}
                  alt="Preview Icon"
                  className="w-28 h-28 object-cover"
                />
              </div>
            </Fragment>
          ) : null}
          <Button type="submit" color="blue" className="w-28">
            Upload
          </Button>
        </form>
      </Modal>
      {postLoading && <BigLoader />}
    </Fragment>
  );
}
