import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import service from "../service";
import { setInterface } from "../redux/slices/interfaceSlice";
import { Helmet } from "react-helmet-async";
import { Fragment } from "react";
import Card from "../components/Card";
import ReadBookList from "../components/ReadBookList";
import Loader from "../components/Loader";
import moment from "moment";
import Table, { Td, Th } from "../components/Table";

export default function UserDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { active } = useSelector((state) => state.interface);

  const getAccess = (id) => {
    return new Promise((resolve) => {
      service
        .get(`/api/stat/user-access/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve({});
        });
    });
  };

  const getReading = (id) => {
    return new Promise((resolve) => {
      service
        .get(`/api/stat/user-reading-log/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  };

  const getBook = (id) => {
    return new Promise((resolve) => {
      service
        .get(`/api/stat/book-was-read/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve([]);
        });
    });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { fullname, ...access } = await getAccess(id);
      const reading = await getReading(id);
      const book = await getBook(id);
      setLoading(false);
      setData({
        fullname,
        read_logs: reading,
        books: book,
        ...access,
      });
    })();
  }, [id]);

  useEffect(() => {
    dispatch(setInterface({ active, pageTitle: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (data.fullname) {
      dispatch(setInterface({ active, pageTitle: data.fullname }));
    }
  }, [data]);

  if (loading) {
    return (
      <div className="h-64 flex justify-center items-center flex-col">
        <Loader className="bg-black-100" />
        <span className="mt-3">Memuat</span>
      </div>
    );
  }
  return (
    <Fragment>
      <Helmet>
        <title>{data.fullname}</title>
      </Helmet>
      <Card className="mb-4">
        <h2 className="font-bold mr-auto text-black-300 mb-5">
          Statistik Akses
        </h2>
        <table className="border-collapse w-full lg:w-1/3">
          <tr>
            <td className="border p-2 border-white-300">Terakhir</td>
            <td className="border p-2 border-white-300">
              {data.last_access
                ? moment(data.last_access).format("DD/MM/YYYY - HH:mm")
                : "-"}
            </td>
          </tr>
          <tr>
            <td className="border p-2 border-white-300">7 hari</td>
            <td className="border p-2 border-white-300">{data.this_week}x</td>
          </tr>
          <tr>
            <td className="border p-2 border-white-300">Semua</td>
            <td className="border p-2 border-white-300">{data.all_time}x</td>
          </tr>
        </table>
      </Card>
      <Card className="mb-4">
        {data.read_logs?.length ? (
          <Fragment>
            <h2 className="font-bold mr-auto text-black-300 mb-5">
              Aktivitas Terakhir
            </h2>
            <Table>
              <thead>
                <tr>
                  <Th className="w-20">NO</Th>
                  <Th className="w-1/3">JUDUL</Th>
                  <Th>RING</Th>
                  <Th className="w-1/5">TANGGAL</Th>
                </tr>
              </thead>
              <tbody>
                {data.read_logs?.map((item, index) => (
                  <tr key={`act-${index}`}>
                    <Td className="text-center align-top">{index + 1}</Td>
                    <Td className="align-top">{item.bab.content.title}</Td>
                    <Td className="align-top">{item.bab.title}</Td>
                    <Td className="text-center align-top">
                      {moment(item.created_at).format("DD/MM/YYYY - HH:mm")}
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Fragment>
        ) : (
          <div className="h-65 flex flex-col justify-center text-center">
            Belum ada aktivitas
          </div>
        )}
      </Card>
      <Card>
        {data.books?.length ? (
          <Fragment>
            <h2 className="font-bold mr-auto text-black-300 mb-5">
              Buku Dibaca
            </h2>
            {data.books?.map((value, index) => (
              <ReadBookList
                imgSrc={value.bab?.content?.cover}
                title={value.bab?.content?.title}
                bab={value.bab?.title}
                createdTime={value.created_at}
                key={`${index}`}
              />
            ))}
          </Fragment>
        ) : (
          <div className="h-65 flex flex-col justify-center text-center">
            Belum ada buku yang dibaca
          </div>
        )}
      </Card>
    </Fragment>
  );
}
