import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export default function Dropzone({
  onChange,
  containerClassName,
  message,
  label,
  dropzone,
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      onChange(acceptedFiles);
    },
    [onChange]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...dropzone,
  });

  return (
    <div className={containerClassName}>
      {label && <label className="text-black-200 font-bold">{label}</label>}
      <div
        {...getRootProps()}
        className={
          "flex justify-center items-center text-center h-32 border border-white-300 rounded-sm p-5 cursor-pointer " +
          (isDragActive ? "bg-green-100 bg-opacity-25" : "bg-white-100")
        }
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Lepaskan file disini</p>
        ) : (
          <p>Tarik dan lepaskan file disini, atau klik untuk memilih</p>
        )}
      </div>

      {message && <span className="text-red-200 text-sm">{message}</span>}
    </div>
  );
}
