import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import Editor from "../../../components/Editor";
import TextInput from "../../../components/TextInput";
import { setInterface } from "../../../redux/slices/interfaceSlice";
import Dropzone from "../../../components/Dropzone";
import { useHistory, useParams } from "react-router-dom";
import swal from "@sweetalert/with-react";
import BigLoader from "../../../components/BigLoader";
import service from "../../../service";
import Loader from "../../../components/Loader";
import ReactAudioPlayer from "react-audio-player";

export default function ComposeSubject({ editMode = false, inParent = false }) {
  const dispatch = useDispatch();
  const { id, parent } = useParams();
  const history = useHistory();
  const [getLoading, setGetLoading] = useState(true);
  const [postLoading, setPostLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [speed, setSpeed] = useState(500);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const getData = () => {
    setGetLoading(true);
    service
      .get(`/api/subject/${id}`)
      .then((response) => {
        setGetLoading(false);

        const { title, body, video, pdf, audio } = response.data;
        reset({
          title,
          body,
          video,
          pdf,
          audio,
        });
      })
      .catch((e) => {
        setGetLoading(false);
        if (e.response?.status === 404) {
          history.replace("/content");
        }
      });
  };

  let newProgress = 0;

  let interval = false;

  const uploadProgress = async (perecent, doneRes) => {
    if (!doneRes) {
      if (!interval) {
        if (newProgress < 100) {
          interval = setInterval(async () => {
            if (newProgress < 100) {
              newProgress += 1;

              await setProgress(newProgress);
            }
          }, perecent / 3568);
        } else {
          setProgress("Done");
        }
      }
    }

    return new Promise((resolve) => {
      if (doneRes) {
        interval = false;
        setTimeout(() => {
          interval = setInterval(async () => {
            if (newProgress < 100) {
              newProgress = newProgress + 1;

              await setProgress(newProgress);
            }
          }, 100);
        }, 500);

        setInterval(() => {
          if (newProgress === 100) {
            interval = false;
            resolve("done");
          }
        }, 100);
      }
    });
  };

  const _proceed = ({ title, body, video, pdf, audio }) => {
    setPostLoading(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("body", body);

    if (video instanceof File) {
      formData.append("video", video);
    }
    if (pdf instanceof File) {
      formData.append("pdf", pdf);
    }
    if (audio instanceof File) {
      formData.append("audio", audio);
    }

    service({
      url: editMode
        ? `/api/subject/${id}`
        : inParent
        ? `/api/subject/${id}`
        : `/api/course/${id}`,
      data: formData,
      method: editMode ? "PUT" : "POST",
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        uploadProgress(progressEvent.total);
      },
    })
      .then((response) => {
        const handleRes = () => {
          newProgress = 0;
          setProgress(0);
          setPostLoading(false);
          swal(
            "Berhasil",
            `Materi telah ${editMode ? "disunting" : "ditambahkan"}`,
            "success"
          ).then((value) => {
            if (value) {
              history.replace(`/course/${parent || id}/subject`);
            }
          });
        };

        if (newProgress === 100) {
          handleRes();
        } else {
          setSpeed(250);
          uploadProgress(false, true).then((res) => {
            handleRes();
          });
        }
      })
      .catch((e) => {
        interval = false;
        setPostLoading(false);
      });
  };

  useEffect(() => {
    dispatch(
      setInterface({
        active: "Online Course",
        pageTitle: `${editMode ? "Edit" : "Tambah"}${
          inParent ? " Sub" : ""
        } Materi`,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (editMode) {
      getData();
    } else {
      setGetLoading(false);
    }
  }, []);

  if (getLoading) {
    return (
      <div className="h-64 flex justify-center items-center flex-col">
        <Helmet>
          <title>{`${editMode ? "Edit" : "Tambah"}${
            inParent ? " Sub" : ""
          } Materi`}</title>
        </Helmet>
        <Loader className="bg-black-100" />
        <span className="mt-3">Memuat</span>
      </div>
    );
  }
  return (
    <Fragment>
      <Helmet>
        <title>{`${editMode ? "Edit" : "Tambah"}${
          inParent ? " Sub" : ""
        } Materi`}</title>
      </Helmet>
      <form
        className="flex flex-col lg:flex-row"
        onSubmit={handleSubmit(_proceed)}
      >
        <div className="w-full lg:w-7/12">
          <Card>
            <TextInput
              label="Judul Materi"
              containerClassName="mb-4"
              message={errors.title?.message}
              {...register("title", { required: "Tidak boleh kosong" })}
            />
            <Controller
              name="body"
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <Editor
                  toolbar={[
                    [{ font: [] }],
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "clean"],
                  ]}
                  label="Isi Materi"
                  containerClassName="mb-4"
                  value={value}
                  message={errors.body?.message}
                  onChange={(text) => {
                    onChange(text);
                  }}
                />
              )}
            />
            <Button type="submit" className="w-24 hidden lg:block">
              {editMode ? "Sunting" : "Tambah"}
            </Button>
          </Card>
        </div>
        <div className="flex-1 ml-0 mt-5 lg:ml-5 lg:mt-0">
          <Card>
            <Controller
              control={control}
              name="video"
              render={({ field: { onChange } }) => (
                <Dropzone
                  label="File Video"
                  dropzone={{
                    accept: "video/*",
                  }}
                  message={errors.video?.message}
                  containerClassName="mb-4"
                  onChange={(accepted) => {
                    onChange(accepted[0]);
                  }}
                />
              )}
            />
            <div className="text-primary-600 font-bold">
              {watch("video")
                ? watch("video") instanceof File
                  ? watch("video").name?.split("/").pop().split("?")[0]
                  : watch("video").split("/").pop().split("?")[0]
                : null}
            </div>
          </Card>
          <Card className="mt-5">
            <Controller
              control={control}
              name="pdf"
              render={({ field: { onChange } }) => (
                <Dropzone
                  label="File PDF"
                  dropzone={{
                    accept: "application/pdf",
                  }}
                  message={errors.pdf?.message}
                  containerClassName="mb-4"
                  onChange={(accepted) => {
                    onChange(accepted[0]);
                  }}
                />
              )}
            />
            <div className="text-primary-600 font-bold break-words">
              {watch("pdf")
                ? watch("pdf") instanceof File
                  ? watch("pdf").path?.split("/").pop().split("?")[0]
                  : watch("pdf").split("/").pop().split("?")[0]
                : null}
            </div>
          </Card>
          <Card className="mt-5">
            <Controller
              control={control}
              name="audio"
              render={({ field: { onChange } }) => (
                <Dropzone
                  label="File Audio"
                  dropzone={{
                    accept: "audio/mpeg, audio/ogg, audio/wav, audio/aac",
                  }}
                  message={errors.audio?.message}
                  containerClassName="mb-4"
                  onChange={(accepted) => {
                    onChange(accepted[0]);
                  }}
                />
              )}
            />

            {watch("audio") && (
              <Fragment>
                <span className="font-bold">Preview :</span>
                <div className="flex justify-center">
                  <ReactAudioPlayer
                    className="w-full mt-2"
                    src={
                      watch("audio") instanceof File
                        ? URL.createObjectURL(watch("audio"))
                        : process.env.REACT_APP_BASE_URL + watch("audio")
                    }
                    controls
                  />
                </div>
              </Fragment>
            )}
          </Card>
        </div>
        <Card className="block lg:hidden mt-5">
          <Button type="submit" className="w-24">
            {editMode ? "Sunting" : "Tambah"}
          </Button>
        </Card>
      </form>
      {/* <BigLoader progress={progress} /> */}
      {postLoading ? <BigLoader progress={progress ? progress : "0"} /> : null}
    </Fragment>
  );
}
