import { createSlice } from "@reduxjs/toolkit";

export const interfaceSlice = createSlice({
  name: "interface",
  initialState: {
    pageTitle: "",
    active: "Dashboard",
  },
  reducers: {
    setInterface: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInterface } = interfaceSlice.actions;

export default interfaceSlice.reducer;
