import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FiPlus } from "react-icons/fi";
import { useDispatch } from "react-redux";
import Button from "../../components/Button";
import Card from "../../components/Card";
import ContentList from "../../components/ContentList";
import Loader from "../../components/Loader";
import { setInterface } from "../../redux/slices/interfaceSlice";
import service from "../../service";
import swal from "@sweetalert/with-react";
import BigLoader from "../../components/BigLoader";
import ReactPaginate from "react-paginate";
import { Fragment } from "react";

export default function Content() {
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [total, setTotal] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const getData = () => {
    setLoading(true);
    service
      .get("/api/content", { params: { page } })
      .then((response) => {
        const { total, data } = response.data;
        setTotal(total);
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onDelete = (id) => {
    swal({
      title: "Anda yakin?",
      text: "Anda akan menghapus konten ini",
      icon: "warning",
      buttons: ["Batal", true],
      dangerMode: true,
      cancelButton: "Batal",
    }).then((value) => {
      if (value) {
        setLoadingDelete(true);
        service.delete(`/api/content/${id}`).then(() => {
          swal("Berhasil", "Konten telah dihapus", "success");
          getData();
        });
      }
    });
  };

  useEffect(() => {
    dispatch(setInterface({ active: "Konten", pageTitle: "Konten" }));
  }, []);

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <Card>
      <Helmet>
        <title>Konten</title>
      </Helmet>
      <div className="mb-10">
        <Button
          link={true}
          to="/content/add"
          color="green"
          className="w-28 flex justify-center items-center"
        >
          <span className="mr-1">
            <FiPlus size={20} />
          </span>
          Tambah
        </Button>
      </div>
      {loading ? (
        <div className="h-64 flex justify-center items-center flex-col">
          <Loader className="bg-black-100" />
          <span className="mt-3">Memuat</span>
        </div>
      ) : data.length ? (
        <Fragment>
          {data.map((item, index) => (
            <ContentList
              imgSrc={item.cover}
              title={item.title}
              babs={item.babs_count}
              key={`${index}`}
              createdTime={item.created_at}
              id={item.id}
              onDelete={onDelete}
            />
          ))}

          <ReactPaginate
            pageCount={total}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            initialPage={page - 1}
            containerClassName="flex flex-wrap mt-8"
            previousLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
            nextLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
            pageLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
            activeLinkClassName="bg-primary-500 border-primary-600 text-white-100"
            onPageChange={({ selected }) => setPage(selected + 1)}
          />
        </Fragment>
      ) : (
        <div className="text-center flex flex-col justify-center items-center h-40">
          <p>Belum ada konten</p>
        </div>
      )}
      {loadingDelete && <BigLoader />}
    </Card>
  );
}
