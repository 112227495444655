import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import Card from "../../components/Card";
import { setInterface } from "../../redux/slices/interfaceSlice";
import service from "../../service";
import ReactSelect from "react-select";
import Button from "../../components/Button";
import { Controller, useForm } from "react-hook-form";
import BigLoader from "../../components/BigLoader";
import swal from "@sweetalert/with-react";

export default function Rajaongkir() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [subdistrict, setSubdistrict] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [subdistrictOptions, setSubdistrictOptions] = useState([]);
  const [province, setProvince] = useState(0);
  const [city, setCity] = useState(0);
  const [couriersOptions] = useState([
    { value: "jne", label: "jne" },
    { value: "pos", label: "pos" },
    { value: "tiki", label: "tiki" },
    { value: "rpx", label: "rpx" },
    { value: "pandu", label: "pandu" },
    { value: "wahana", label: "wahana" },
    { value: "sicepat", label: "sicepat" },
    { value: "jnt", label: "jnt" },
    { value: "pahala", label: "pahala" },
    { value: "sap", label: "sap" },
    { value: "jet", label: "jet" },
    { value: "indah", label: "indah" },
    { value: "dse", label: "dse" },
    { value: "slis", label: "slis" },
    { value: "first", label: "first" },
    { value: "ncs", label: "ncs" },
    { value: "star", label: "star" },
    { value: "ninja", label: "ninja" },
    { value: "lion", label: "lion" },
    { value: "idl", label: "idl" },
    { value: "rex", label: "rex" },
    { value: "ide", label: "ide" },
    { value: "sentral", label: "sentral" },
    { value: "anteraja", label: "anteraja" },
  ]);
  const { control, handleSubmit, setValue } = useForm();

  const getValue = () => {
    service
      .get("/api/config/rajaongkir")
      .then((response) => {
        const { data } = response;

        setValue("subdistrict", data.subdistrict);
        if (data.subdistrict.province_id) {
          setProvince(data.subdistrict.province_id);
        }
        if (data.subdistrict.city_id) {
          setCity(data.subdistrict.city_id);
        }

        setValue("couriers", data.couriers);
      })
      .catch((e) => {});
  };

  const loadProvince = () => {
    setProvinceOptions([]);
    setCityOptions([]);
    setSubdistrictOptions([]);
    service
      .get("/api/shipping/province")
      .then((response) => {
        const { data } = response;
        const province = data.map((item) => ({
          value: item.province_id,
          label: item.province,
        }));
        setProvinceOptions(province);
      })
      .catch((e) => {});
  };

  const loadCity = (id) => {
    setCityOptions([]);
    setSubdistrictOptions([]);
    service
      .get("/api/shipping/city", {
        params: {
          id,
        },
      })
      .then((response) => {
        const { data } = response;

        const cities = data.map((item) => ({
          value: item.city_id,
          label: item.city_name,
        }));
        setCityOptions(cities);
      })
      .catch((e) => {});
  };

  const loadSubdistrict = (id) => {
    setSubdistrictOptions([]);
    service
      .get("/api/shipping/subdistrict", {
        params: {
          id,
        },
      })
      .then((response) => {
        const { data } = response;

        setSubdistrict(data);

        const subdistricts = data.map((item) => ({
          value: item.subdistrict_id,
          label: item.subdistrict_name,
        }));
        setSubdistrictOptions(subdistricts);
      })
      .catch((e) => {});
  };

  const _proceed = (data) => {
    setLoading(true);
    service
      .post("/api/config/rajaongkir", {
        ...data,
      })
      .then(() => {
        setLoading(false);
        swal("Berhasil", "Pengaturan telah disimpan", "success");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadProvince();
    getValue();
  }, []);

  useEffect(() => {
    loadCity(province);
  }, [province]);

  useEffect(() => {
    loadSubdistrict(city);
  }, [city]);

  useEffect(() => {
    dispatch(setInterface({ active: "Rajaongkir", pageTitle: "Rajaongkir" }));
  }, [dispatch]);
  return (
    <Fragment>
      <Helmet>
        <title>Rajaongkir</title>
      </Helmet>
      <form onSubmit={handleSubmit(_proceed)}>
        <Card className="flex-1">
          <div className="w-full lg:w-1/2">
            <div className="mb-4">
              <label className="text-black-200 font-bold">Provinsi</label>
              <ReactSelect
                styles={{
                  control: (base) => ({
                    ...base,
                    border: 0,
                    // This line disable the blue border
                    boxShadow: "none",
                  }),
                }}
                value={
                  province
                    ? provinceOptions.find(
                        (el) => `${el.value}` === `${province}`
                      )
                    : {}
                }
                onChange={(value) => {
                  setProvince(value.value);
                }}
                options={provinceOptions}
                classNamePrefix="react-select"
                className="react-select-container mb-4"
              />
            </div>
            <div className="mb-4">
              <label className="text-black-200 font-bold">Kota/Kabupaten</label>
              <ReactSelect
                styles={{
                  control: (base) => ({
                    ...base,
                    border: 0,
                    // This line disable the blue border
                    boxShadow: "none",
                  }),
                }}
                value={
                  city
                    ? cityOptions.find((el) => `${el.value}` === `${city}`)
                    : {}
                }
                onChange={(value) => {
                  setCity(value.value);
                }}
                options={cityOptions}
                classNamePrefix="react-select"
                className="react-select-container mb-4"
              />
            </div>

            <Controller
              name="subdistrict"
              rules={{ required: "Tidak Boleh Kosong" }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="mb-4">
                  <label className="text-black-200 font-bold">Kecamatan</label>
                  <ReactSelect
                    value={
                      value
                        ? subdistrictOptions.find(
                            (el) => `${el.value}` === `${value.subdistrict_id}`
                          )
                        : {}
                    }
                    onChange={(value) => {
                      onChange(
                        subdistrict.find(
                          (el) => `${el.subdistrict_id}` === `${value.value}`
                        )
                      );
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: 0,
                        // This line disable the blue border
                        boxShadow: "none",
                      }),
                    }}
                    options={subdistrictOptions}
                    classNamePrefix="react-select"
                    className="react-select-container mb-4"
                  />
                </div>
              )}
            />

            <Controller
              name="couriers"
              rules={{ required: "Tidak Boleh Kosong" }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="mb-4">
                  <label className="text-black-200 font-bold">Kurir</label>
                  <ReactSelect
                    onChange={(inputValue) => {
                      const couriers = [];
                      for (let value of inputValue) {
                        couriers.push(value.value);
                      }
                      onChange(couriers);
                    }}
                    value={(() => {
                      const results = [];
                      if (value) {
                        for (let item of value) {
                          results.push({
                            value: item,
                            label: item,
                          });
                        }
                      }

                      return results;
                    })()}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: 0,
                        // This line disable the blue border
                        boxShadow: "none",
                      }),
                    }}
                    isMulti
                    options={couriersOptions}
                    classNamePrefix="react-select"
                    className="react-select-container mb-4"
                  />
                </div>
              )}
            />
          </div>

          <Button type="submit" className="w-24">
            Simpan
          </Button>
        </Card>
        {loading ? <BigLoader /> : null}
      </form>
    </Fragment>
  );
}
