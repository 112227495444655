import { Fragment, useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BigLoader from "../../../components/BigLoader";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import Dropzone from "../../../components/Dropzone";
import Editor from "../../../components/Editor";
import Loader from "../../../components/Loader";
import TextInput from "../../../components/TextInput";
import { setInterface } from "../../../redux/slices/interfaceSlice";
import service from "../../../service";
import swal from "@sweetalert/with-react";

export default function ComposeBab({ editMode = false }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingContent, setLoadingContent] = useState(true);
  const [loading, setLoading] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      title: "",
      body: "",
    },
  });

  const _proceed = ({ title, body, audio }) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("body", body);

    if (audio instanceof File) {
      formData.append("audio", audio);
    }

    service({
      url: editMode ? `/api/bab/${id}` : `/api/content/${id}`,
      data: formData,
      method: editMode ? "PUT" : "POST",
    })
      .then(() => {
        setLoading(false);
        swal(
          "Berhasil",
          `Ring telah ${editMode ? "disunting" : "ditambahkan"}`,
          "success"
        ).then((value) => {
          if (value && !editMode) {
            history.replace(`/content/${id}/bab`);
          } else {
            setValue("audio", `/api/bab/${id}/stream`);
          }
        });
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(
      setInterface({
        active: "Konten",
        pageTitle: editMode ? "Edit Ring" : "Tambah Ring",
      })
    );
  }, [dispatch, editMode]);

  useEffect(() => {
    service
      .get(editMode ? `/api/bab/${id}` : "/api/content/" + id)
      .then((response) => {
        setLoadingContent(false);
        if (editMode) {
          const { title, body, audio } = response.data;
          reset({
            title,
            body,
            audio,
          });
        }
      })
      .catch((e) => {
        setLoadingContent(false);
        if (e.response?.status === 404) {
          history.replace("/content");
        }
      });
  }, [editMode, id]);

  if (loadingContent) {
    return (
      <div className="h-64 flex justify-center items-center flex-col">
        <Helmet>
          <title>{editMode ? "Edit" : "Tambah"} Ring</title>
        </Helmet>
        <Loader className="bg-black-100" />
        <span className="mt-3">Memuat</span>
      </div>
    );
  }
  return (
    <form
      className="flex flex-col lg:flex-row"
      onSubmit={handleSubmit(_proceed)}
    >
      <Helmet>
        <title>{editMode ? "Edit" : "Tambah"} Ring</title>
      </Helmet>
      <Card className="w-full lg:w-7/12">
        <TextInput
          label="Judul Ring"
          containerClassName="mb-4"
          message={errors.title?.message}
          {...register("title", { required: "Tidak boleh kosong" })}
        />
        <Controller
          control={control}
          name="body"
          rules={{ required: "Tidak boleh kosong" }}
          render={({ field: { value, onChange } }) => (
            <Editor
              label="Isi Ring"
              containerClassName="mb-4"
              value={value}
              message={errors.body?.message}
              onChange={(text) => {
                onChange(text);
              }}
            />
          )}
        />
        <Button type="submit" className="w-24 hidden lg:block">
          {editMode ? "Edit" : "Tambah"}
        </Button>
      </Card>
      <div className="flex-1 ml-0 mt-5 lg:ml-5 lg:mt-0">
        <Card>
          <Controller
            control={control}
            name="audio"
            rules={{ required: "Tidak boleh kosong" }}
            render={({ field: { onChange } }) => (
              <Dropzone
                label="File Audio"
                dropzone={{
                  accept: "audio/mpeg, audio/ogg, audio/wav, audio/aac",
                }}
                message={errors.audio?.message}
                containerClassName="mb-4"
                onChange={(accepted) => {
                  onChange(accepted[0]);
                }}
              />
            )}
          />

          {watch("audio") && (
            <Fragment>
              <span className="font-bold">Preview :</span>
              <div className="flex justify-center">
                <ReactAudioPlayer
                  className="w-full mt-2"
                  src={
                    watch("audio") instanceof File
                      ? URL.createObjectURL(watch("audio"))
                      : process.env.REACT_APP_BASE_URL + watch("audio")
                  }
                  controls
                />
              </div>
            </Fragment>
          )}
        </Card>
      </div>
      <Card className="block lg:hidden mt-5">
        <Button type="submit" className="w-24">
          {editMode ? "Edit" : "Tambah"}
        </Button>
      </Card>
      {loading && <BigLoader />}
    </form>
  );
}
