import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { FiBell, FiBox, FiEye, FiPrinter } from "react-icons/fi";
import NumberFormat from "react-number-format";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import swal from "@sweetalert/with-react";
import Card from "../../components/Card";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import Table, { Td, Th } from "../../components/Table";
import { setInterface } from "../../redux/slices/interfaceSlice";
import service from "../../service";
import BigLoader from "../../components/BigLoader";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import ReactSelect from "react-select";

export default function Order() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [detail, setDetail] = useState([]);
  const [shipping, setShipping] = useState({});
  const [page, setPage] = useState(1);
  const [edit, setEdit] = useState(0);
  const _modalDetail = useRef();
  const _modalResi = useRef();
  const _modalStatus = useRef();

  const statusOptions = [
    {
      value: "1",
      label: "Order Diterima",
    },
    {
      value: "2",
      label: "Order Diproses",
    },
    {
      value: "3",
      label: "Order Terkirim",
    },
  ];

  const { register, handleSubmit, reset } = useForm();
  const {
    control,
    handleSubmit: handleStatusSubmit,
    reset: resetStatus,
  } = useForm();

  const getDetail = (id) => {
    setLoadingDetail(true);
    service
      .get("/api/checkout/" + id)
      .then((response) => {
        setLoadingDetail(false);
        const { items } = response.data;
        setDetail(items);
      })
      .catch((e) => {
        setLoadingDetail(false);
      });
  };

  const getData = () => {
    setLoading(true);
    service
      .get("/api/checkout", { params: { page } })
      .then((response) => {
        const { total, data } = response.data;
        setTotal(total);
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const saveResi = ({ resi }) => {
    setLoadingPost(true);
    service
      .put("/api/checkout/resi/" + edit, {
        resi,
      })
      .then(() => {
        setLoadingPost(false);
        getData();
        swal("Berhasil", "Resi telah disimpan", "success");
        _modalResi.current.hide();
      })
      .catch(() => {
        setLoadingPost(false);
      });
  };

  const onPrint = () => {
    service
      .get("/api/checkout/get-print")
      .then((response) => {
        window.location.href =
          process.env.REACT_APP_BASE_URL + response.data.url;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const saveStatus = ({ status }) => {
    setLoadingPost(true);
    service
      .put("/api/checkout/status/" + edit, {
        status,
      })
      .then(() => {
        setLoadingPost(false);
        getData();
        swal("Berhasil", "Status telah disimpan", "success");
        _modalStatus.current.hide();
      })
      .catch(() => {
        setLoadingPost(false);
      });
  };

  useEffect(() => {
    dispatch(setInterface({ active: "Pesanan", pageTitle: "Pesanan" }));
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <Fragment>
      <Helmet>
        <title>Pesanan</title>
      </Helmet>
      <Card>
        <div className="mb-4 flex">
          <button
            onClick={onPrint}
            type="button"
            className="px-3 py-2 h-12 rounded-sm bg-red-200 hover:bg-red-300 text-white-100"
            href={`${process.env.REACT_APP_BASE_URL}/api/checkout/print`}
          >
            <FiPrinter className="inline-block" /> Print
          </button>
        </div>
        {loading ? (
          <div className="h-64 flex justify-center items-center flex-col">
            <Loader className="bg-black-100" />
            <span className="mt-3">Memuat</span>
          </div>
        ) : data.length ? (
          <Fragment>
            <Table>
              <thead>
                <tr>
                  <Th>TANGGAL</Th>
                  <Th>NAMA USER</Th>
                  <Th>TOTAL</Th>
                  <Th>STATUS</Th>
                  <Th>RESI</Th>
                  <Th>AKSI</Th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  let statusColor = item.is_paid
                    ? "text-yellow-300"
                    : "text-red-300";
                  let status = item.is_paid ? "Diterima" : "Belum Dibayar";
                  if (item.status === 2) {
                    statusColor = "text-primary-500";
                    status = "Diproses";
                  } else if (item.status === 3) {
                    statusColor = "text-green-300";
                    status = "Terkirim";
                  }
                  return (
                    <tr key={`list-${index}`}>
                      <Td className="text-center">
                        {moment(item.created_at).format("HH:mm DD/MM/YYYY")}
                      </Td>
                      <Td>{item.user?.fullname}</Td>
                      <Td className="text-right">
                        <NumberFormat
                          prefix="Rp"
                          thousandSeparator={true}
                          value={item.total}
                          displayType="text"
                        />
                      </Td>
                      <Td className="text-center">
                        <span className={statusColor}>{status}</span>
                      </Td>
                      <Td className="text-center">
                        {item.detail?.shipping ? (
                          item.resi || "-"
                        ) : (
                          <div className="text-red-100">Bukan Produk Fisik</div>
                        )}
                      </Td>
                      <Td>
                        <div className="flex justify-center">
                          <button
                            onClick={() => {
                              if (item.detail?.shipping) {
                                setShipping(item.detail?.shipping);
                              } else {
                                setShipping(null);
                              }

                              getDetail(item.id);

                              _modalDetail.current.show();
                            }}
                            type="button"
                            className="py-2 px-3 border border-white-300 text-sm hover:bg-primary-500 hover:border-primary-600 hover:text-white-100"
                          >
                            <FiEye />
                          </button>
                          {item.detail?.shipping && item.is_paid && (
                            <button
                              onClick={() => {
                                setEdit(item.id);
                                _modalResi.current.show();
                                reset({
                                  resi: item.resi,
                                });
                              }}
                              type="button"
                              className="py-2 px-3 border border-white-300 text-sm hover:bg-green-200 hover:border-green-300 hover:text-white-100 ml-3"
                            >
                              <FiBox />
                            </button>
                          )}
                          {item.is_paid && (
                            <button
                              onClick={() => {
                                setEdit(item.id);
                                _modalStatus.current.show();
                                resetStatus({
                                  status: `item.status`,
                                });
                              }}
                              type="button"
                              className="py-2 px-3 border border-white-300 text-sm hover:bg-yellow-200 hover:border-yellow-300 ml-3"
                            >
                              <FiBell />
                            </button>
                          )}
                        </div>
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <ReactPaginate
              pageCount={total}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              initialPage={page - 1}
              containerClassName="flex flex-wrap mt-8"
              previousLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
              nextLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
              pageLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
              activeLinkClassName="bg-primary-500 border-primary-600 text-white-100"
              onPageChange={({ selected }) => setPage(selected + 1)}
            />
          </Fragment>
        ) : (
          <div className="text-center flex flex-col justify-center items-center h-40">
            <p>Belum ada konten</p>
          </div>
        )}
      </Card>
      <Modal title="Detail" ref={_modalDetail}>
        {loadingDetail ? (
          <div className="h-64 flex justify-center items-center flex-col">
            <Loader className="bg-black-100" />
            <span className="mt-3">Memuat</span>
          </div>
        ) : (
          <Fragment>
            {shipping && (
              <div className="border border-yellow-300 bg-yellow-100 p-3 mb-3">
                <div className="font-bold">
                  {shipping.recipient_name} ({shipping.recipient_phone})
                </div>
                <div className="mt-2">{shipping.destination}</div>
              </div>
            )}
            <Table>
              <thead>
                <tr>
                  <Th>NO</Th>
                  <Th>PRODUK</Th>
                  <Th>HARGA</Th>
                  <Th>QTY</Th>
                  <Th>TOTAL</Th>
                </tr>
              </thead>
              <tbody>
                {detail.map((item, index) => (
                  <tr key={`key-${index}`}>
                    <Td>{index + 1}</Td>
                    <Td>{item.title}</Td>
                    <Td>
                      <NumberFormat
                        prefix="Rp"
                        thousandSeparator={true}
                        value={item.price}
                        displayType="text"
                      />
                    </Td>
                    <Td>{item.qty}</Td>
                    <Td>
                      <NumberFormat
                        prefix="Rp"
                        thousandSeparator={true}
                        value={item.qty * item.price}
                        displayType="text"
                      />
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Fragment>
        )}
      </Modal>
      <Modal title="Edit Resi" ref={_modalResi}>
        <form onSubmit={handleSubmit(saveResi)}>
          <TextInput
            label="Resi"
            type="text"
            containerClassName="mb-4"
            {...register("resi")}
          />
          <Button type="submit" color="blue" className="w-28">
            Simpan
          </Button>
        </form>
      </Modal>
      <Modal title="Ganti Status" ref={_modalStatus}>
        <form onSubmit={handleStatusSubmit(saveStatus)}>
          <Controller
            control={control}
            rules={{ required: true }}
            name="status"
            render={({ field: { value, onChange } }) => (
              <ReactSelect
                value={
                  value ? statusOptions.find((el) => el.value === value) : null
                }
                styles={{
                  control: (base) => ({
                    ...base,
                    border: 0,
                    // This line disable the blue border
                    boxShadow: "none",
                  }),
                }}
                onChange={({ value }) => {
                  onChange(value);
                }}
                options={statusOptions}
                classNamePrefix="react-select"
                className="react-select-container mb-4"
              />
            )}
          />
          <Button type="submit" color="blue" className="w-28">
            Simpan
          </Button>
        </form>
      </Modal>
      {loadingPost && <BigLoader />}
    </Fragment>
  );
}
