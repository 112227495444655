import { Fragment, useRef, useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { useDispatch } from "react-redux";
import BigLoader from "../../components/BigLoader";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Modal from "../../components/Modal";
import PartnerList from "../../components/PartnerList";
import TextInput from "../../components/TextInput";
import { setInterface } from "../../redux/slices/interfaceSlice";
import service from "../../service";
import swal from "@sweetalert/with-react";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import Dropzone from "../../components/Dropzone";

export default function Partner() {
  const _composeModal = useRef();
  const _coverModal = useRef();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [postLoading, setPostLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(true);
  const [edit, setEdit] = useState(0);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    dispatch(setInterface({ active: "Partner", pageTitle: "Partner" }));
  }, [dispatch]);
  const {
    control,
    handleSubmit: handleUpload,
    watch,
    reset: resetLogo,
  } = useForm();

  const getData = () => {
    setGetLoading(true);
    service
      .get("/api/partner", {
        params: {
          page,
        },
      })
      .then((response) => {
        setGetLoading(false);
        setData(response.data.data);
        setTotal(response.data.total);
      })
      .catch((e) => {
        setGetLoading(false);
      });
  };

  const onDelete = (id) => {
    swal({
      title: "Anda yakin?",
      text: "Anda akan menghapus partner ini",
      icon: "warning",
      buttons: ["Batal", true],
      dangerMode: true,
      cancelButton: "Batal",
    }).then((value) => {
      if (value) {
        setPostLoading(true);
        service
          .delete(`/api/partner/${id}`)
          .then(() => {
            getData();
            setPostLoading(false);
            swal("Berhasil", `Partner berhasil dihapus`, "success");
          })
          .catch((e) => {
            setPostLoading(false);
          });
      }
    });
  };

  const _proceed = ({ name, amemberGroup, username, password }) => {
    setPostLoading(true);
    service(edit ? `/api/partner/${edit}` : "/api/partner", {
      data: {
        name,
        amemberGroup,
        username,
        password,
      },
      method: edit ? "PUT" : "POST",
    })
      .then((response) => {
        setPostLoading(false);
        getData();
        swal(
          "Berhasil",
          `Partner berhasil ${edit ? "disunting" : "ditambahkan"}`,
          "success"
        ).then((value) => {
          if (value) {
            _composeModal.current.hide();
          }
        });
      })
      .catch((e) => {
        setPostLoading(false);
      });
  };

  const onUploadLogo = ({ logo }) => {
    setPostLoading(true);
    const formData = new FormData();
    formData.append("logo", logo);

    service
      .put(`/api/partner/${edit}/logo`, formData)
      .then(() => {
        setPostLoading(false);
        swal("Berhasil", "Logo berhasil diganti", "success").then((value) => {
          if (value) {
            _coverModal.current.hide();
          }
        });
      })
      .catch(() => {
        setPostLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [page]);
  return (
    <Fragment>
      <Helmet>
        <title>Partner</title>
      </Helmet>
      <Card>
        <Fragment>
          <div className="mb-10">
            <Button
              onClick={() => {
                setEdit(0);
                reset({
                  name: "",
                  amemberGroup: "",
                  username: "",
                  password: "",
                });
                _composeModal.current.show();
              }}
              color="green"
              className="w-28 flex justify-center items-center"
            >
              <span className="mr-1">
                <FiPlus size={20} />
              </span>
              Tambah
            </Button>
          </div>
          {getLoading ? (
            <div className="h-64 flex justify-center items-center flex-col">
              <Loader className="bg-black-100" />
              <span className="mt-3">Memuat</span>
            </div>
          ) : data.length > 0 ? (
            <Fragment>
              <div className="border-l-2 border-white-400">
                {data.map((value, index) => (
                  <PartnerList
                    key={`${index}`}
                    id={value.id}
                    name={value.name}
                    onEdit={(id) => {
                      setEdit(id);
                      reset({
                        name: value.name,
                        amemberGroup: value.amember_group,
                        username: value.admins[0].username,
                        password: "",
                      });

                      _composeModal.current.show();
                    }}
                    onDelete={(id) => {
                      onDelete(id);
                    }}
                  />
                ))}
              </div>
              <ReactPaginate
                pageCount={total}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                initialPage={page - 1}
                containerClassName="flex flex-wrap mt-8"
                previousLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
                nextLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
                pageLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
                activeLinkClassName="bg-primary-500 border-primary-600 text-white-100"
                onPageChange={({ selected }) => setPage(selected + 1)}
              />
            </Fragment>
          ) : (
            <div className="text-center flex flex-col justify-center items-center h-40">
              <p>Belum ada konten</p>
            </div>
          )}
        </Fragment>
      </Card>
      <Modal ref={_composeModal} title={`${edit ? "Edit" : "Tambah"} Partner`}>
        <form onSubmit={handleSubmit(_proceed)}>
          <TextInput
            type="text"
            label="Nama"
            containerClassName="mb-4"
            message={errors.name?.message}
            {...register("name", { required: "Tidak boleh kosong" })}
          />
          <TextInput
            type="number"
            label="Amember Group ID"
            containerClassName="mb-4"
            message={errors.amemberGroup?.message}
            {...register("amemberGroup", { required: "Tidak boleh kosong" })}
          />
          <TextInput
            type="text"
            label="Username"
            containerClassName="mb-4"
            message={errors.username?.message}
            {...register("username", { required: "Tidak boleh kosong" })}
          />
          <TextInput
            type="text"
            label="Password"
            containerClassName="mb-4"
            message={errors.password?.message}
            {...register("password", {
              required: edit ? false : "Tidak boleh kosong",
            })}
          />
          <div className="flex justify-between">
            <Button type="submit" color="blue" className="w-28">
              Simpan
            </Button>
            <Button
              type="button"
              color="white"
              onClick={() => {
                resetLogo({ logo: null });
                _coverModal.current.show();
              }}
              className="w-28"
            >
              Ganti Logo
            </Button>
          </div>
        </form>
      </Modal>
      <Modal ref={_coverModal} title="Ganti Logo">
        <form onSubmit={handleUpload(onUploadLogo)}>
          <Controller
            name="logo"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <Dropzone
                containerClassName="mb-4"
                onChange={(accepted) => {
                  onChange(accepted[0]);
                }}
                dropzone={{ accept: "image/jpeg, image/png" }}
              />
            )}
          />
          {watch("logo") ? (
            <Fragment>
              <span className="font-bold">Preview :</span>
              <div className="flex justify-center mb-4">
                <img
                  src={URL.createObjectURL(watch("logo"))}
                  alt="Preview Logo"
                  className="w-28 h-28 object-cover"
                />
              </div>
            </Fragment>
          ) : null}
          <Button type="submit" color="blue" className="w-28">
            Upload
          </Button>
        </form>
      </Modal>
      {postLoading && <BigLoader />}
    </Fragment>
  );
}
