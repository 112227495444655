import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import Card from "../../components/Card";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import { setInterface } from "../../redux/slices/interfaceSlice";
import service from "../../service";
import Dropzone from "../../components/Dropzone";
import Button from "../../components/Button";
import ProductList from "../../components/ProductList";
import BigLoader from "../../components/BigLoader";
import swal from "@sweetalert/with-react";
import TextInput from "../../components/TextInput";

export default function Product() {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const _modalCover = useRef();
  const _modalWeight = useRef();
  const [id, setId] = useState();
  const { handleSubmit, control, watch, reset } = useForm();
  const {
    handleSubmit: handleWeightSubmit,
    register,
    reset: weightReset,
  } = useForm();
  const [postLoading, setPostLoading] = useState(false);

  const changeCover = ({ cover }) => {
    setPostLoading(true);

    const formData = new FormData();
    formData.append("cover", cover);

    service
      .put(`/api/product/${id}/cover`, formData)
      .then(() => {
        _modalCover.current.hide();
        setPostLoading(false);
        getData();
        swal("Berhasil", `Gambar berhasil diganti`, "success");
      })
      .catch(() => {
        setPostLoading(false);
      });
  };

  const onDelete = (id) => {
    swal({
      title: "Anda yakin?",
      text: "Anda akan menghapus produk ini",
      icon: "warning",
      buttons: ["Batal", true],
      dangerMode: true,
      cancelButton: "Batal",
    }).then((value) => {
      if (value) {
        setPostLoading(true);
        service
          .delete(`/api/product/${id}`)
          .then(() => {
            getData();
            setPostLoading(false);
            swal("Berhasil", `Produk berhasil dihapus`, "success");
          })
          .catch((e) => {
            setPostLoading(false);
          });
      }
    });
  };

  const changeWeight = ({ weight }) => {
    setPostLoading(true);

    service
      .put(`/api/product/${id}/weight`, {
        weight,
      })
      .then(() => {
        _modalWeight.current.hide();
        setPostLoading(false);
        getData();
        swal("Berhasil", `Berat berhasil diatur`, "success");
      })
      .catch(() => {
        setPostLoading(false);
      });
  };

  const getData = () => {
    setLoading(true);
    service
      .get("/api/product", { params: { page } })
      .then((response) => {
        const { total, data } = response.data;
        setTotal(total);
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(setInterface({ active: "Produk", pageTitle: "Produk" }));
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <Fragment>
      <Helmet>
        <title>Produk</title>
      </Helmet>
      <Card>
        {loading ? (
          <div className="h-64 flex justify-center items-center flex-col">
            <Loader className="bg-black-100" />
            <span className="mt-3">Memuat</span>
          </div>
        ) : data.length ? (
          <Fragment>
            {data.map((item, index) => (
              <ProductList
                imgSrc={item.cover}
                title={item.title}
                key={`${index}`}
                id={item.id}
                price={item.price}
                onDelete={onDelete}
                onChangeImage={(id) => {
                  setId(id);
                  reset({ cover: null });
                  _modalCover.current.show();
                }}
                onChangeWeight={(id) => {
                  setId(id);
                  weightReset({ weight: item.weight });
                  _modalWeight.current.show();
                }}
              />
            ))}

            <ReactPaginate
              pageCount={total}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              initialPage={page - 1}
              containerClassName="flex flex-wrap mt-8"
              previousLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
              nextLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
              pageLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
              activeLinkClassName="bg-primary-500 border-primary-600 text-white-100"
              onPageChange={({ selected }) => setPage(selected + 1)}
            />
          </Fragment>
        ) : (
          <div className="text-center flex flex-col justify-center items-center h-40">
            <p>Belum ada konten</p>
          </div>
        )}
      </Card>
      <Modal title="Ganti Gambar" ref={_modalCover}>
        <form onSubmit={handleSubmit(changeCover)}>
          <Controller
            name="cover"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <Dropzone
                containerClassName="mb-4"
                onChange={(accepted) => {
                  onChange(accepted[0]);
                }}
                dropzone={{ accept: "image/jpeg, image/png" }}
              />
            )}
          />
          {watch("cover") ? (
            <Fragment>
              <span className="font-bold">Preview :</span>
              <div className="flex justify-center mb-4">
                <img
                  src={URL.createObjectURL(watch("cover"))}
                  alt="Preview Cover"
                  className="w-28 h-28 object-cover"
                />
              </div>
            </Fragment>
          ) : null}
          <Button type="submit" color="blue" className="w-28">
            Simpan
          </Button>
        </form>
      </Modal>
      <Modal title="Atur Berat" ref={_modalWeight}>
        <form onSubmit={handleWeightSubmit(changeWeight)}>
          <TextInput
            label="Berat (gram)"
            type="number"
            containerClassName="mb-4"
            {...register("weight", { required: true })}
          />
          <Button type="submit" color="blue" className="w-28">
            Simpan
          </Button>
        </form>
      </Modal>

      {postLoading ? <BigLoader /> : null}
    </Fragment>
  );
}
