import swal from "@sweetalert/with-react";
import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { useDispatch } from "react-redux";
import BankList from "../../components/BankList";
import BigLoader from "../../components/BigLoader";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import { setInterface } from "../../redux/slices/interfaceSlice";
import service from "../../service";

export default function Bank() {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState();
  const [data, setData] = useState([]);
  const [getLoading, setGetLoading] = useState(true);
  const [postLoading, setPostLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      account_name: "",
      account_number: "",
      bank_name: "",
    },
  });
  const _composeModal = useRef();

  const getData = () => {
    setGetLoading(true);
    service
      .get("/api/bank")
      .then((response) => {
        setGetLoading(false);
        setData(response.data);
      })
      .catch((e) => {
        setGetLoading(false);
      });
  };

  const onDelete = (id) => {
    swal({
      title: "Anda yakin?",
      text: "Anda akan menghapus bank ini",
      icon: "warning",
      buttons: ["Batal", true],
      dangerMode: true,
      cancelButton: "Batal",
    }).then((value) => {
      if (value) {
        setPostLoading(true);
        service
          .delete(`/api/bank/${id}`)
          .then(() => {
            getData();
            setPostLoading(false);
            swal("Berhasil", `Bank berhasil dihapus`, "success");
          })
          .catch((e) => {
            setPostLoading(false);
          });
      }
    });
  };

  const _proceed = ({ account_name, account_number, bank_name }) => {
    setPostLoading(true);
    service(edit ? `/api/bank/${edit}` : "/api/bank", {
      data: {
        account_name,
        account_number,
        bank_name,
      },
      method: edit ? "PUT" : "POST",
    })
      .then(() => {
        setPostLoading(false);
        getData();
        swal(
          "Berhasil",
          `Bank berhasil ${edit ? "disunting" : "ditambahkan"}`,
          "success"
        ).then((value) => {
          if (value) {
            _composeModal.current.hide();
          }
        });
      })
      .catch((e) => {
        setPostLoading(false);
      });
  };

  useEffect(() => {
    dispatch(setInterface({ active: "Bank", pageTitle: "Bank" }));
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Bank</title>
      </Helmet>
      <Card>
        <div className="mb-10">
          <Button
            onClick={() => {
              setEdit(0);
              reset({
                account_name: "",
                account_number: "",
                bank_name: "",
              });
              _composeModal.current.show();
            }}
            color="green"
            className="w-28 flex justify-center items-center"
          >
            <span className="mr-1">
              <FiPlus size={20} />
            </span>
            Tambah
          </Button>
        </div>
        {getLoading ? (
          <div className="h-64 flex justify-center items-center flex-col">
            <Loader className="bg-black-100" />
            <span className="mt-3">Memuat</span>
          </div>
        ) : data.length > 0 ? (
          <Fragment>
            <div className="border-l-2 border-white-400">
              {data.map((value, index) => (
                <BankList
                  key={`${index}`}
                  id={value.id}
                  name={value.account_name}
                  bank={value.bank_name}
                  number={value.account_number}
                  onEdit={(id) => {
                    setEdit(id);
                    reset({
                      account_name: value.account_name,
                      account_number: value.account_number,
                      bank_name: value.bank_name,
                    });

                    _composeModal.current.show();
                  }}
                  onDelete={(id) => {
                    onDelete(id);
                  }}
                />
              ))}
            </div>
          </Fragment>
        ) : (
          <div className="text-center flex flex-col justify-center items-center h-40">
            <p>Belum ada konten</p>
          </div>
        )}
      </Card>
      <Modal ref={_composeModal} title={`${edit ? "Edit" : "Tambah"} Bank`}>
        <form onSubmit={handleSubmit(_proceed)}>
          <TextInput
            type="text"
            label="Nama Rekening"
            containerClassName="mb-4"
            message={errors.account_name?.message}
            {...register("account_name", { required: "Tidak boleh kosong" })}
          />
          <TextInput
            type="number"
            label="Nomor Rekening"
            containerClassName="mb-4"
            message={errors.account_number?.message}
            {...register("account_number", { required: "Tidak boleh kosong" })}
          />
          <TextInput
            type="text"
            label="Nama Bank"
            containerClassName="mb-4"
            message={errors.bank_name?.message}
            {...register("bank_name", { required: "Tidak boleh kosong" })}
          />
          <div className="flex justify-between">
            <Button type="submit" color="blue" className="w-28">
              Simpan
            </Button>
          </div>
        </form>
      </Modal>
      {postLoading && <BigLoader />}
    </Fragment>
  );
}
