import moment from "moment";
import { FiBookOpen, FiClock, FiEdit, FiEdit2, FiTrash } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function ContentList({
  title,
  babs,
  imgSrc,
  createdTime,
  id,
  onDelete,
}) {
  return (
    <div className="border-b border-white-200 mt-5 pb-5 flex">
      <img
        src={process.env.REACT_APP_BASE_URL + imgSrc}
        alt={"Cover " + title}
        className="w-20 lg:w-24 h-20 lg:h-24 object-cover"
      />
      <div className="flex-1 ml-5">
        <h3 className="font-bold text-base md:text-lg">{title}</h3>
        <div className="flex flex-wrap mt-1">
          <span className="flex items-center text-sm mr-5">
            <FiClock className="mr-2" />{" "}
            {moment(createdTime).format("DD/MM/YYYY")}
          </span>
          <span className="flex items-center text-sm mr-5">
            <FiBookOpen className="mr-2" /> {babs} Ring
          </span>
        </div>
        <div className="flex flex-wrap">
          <Link
            to={"/content/edit/" + id}
            className="py-1 px-3 border border-white-300 text-sm hover:bg-yellow-200 hover:border-yellow-300 flex items-center mr-3 mt-3"
          >
            <FiEdit2 className="mr-1" />
            Edit
          </Link>
          <Link
            to={"/content/" + id + "/bab"}
            className="py-1 px-3 border border-white-300 text-sm hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 flex items-center mr-3 mt-3"
          >
            <FiEdit className="mr-1" />
            Kelola Ring
          </Link>
          <button
            onClick={() => {
              onDelete(id);
            }}
            type="button"
            className="py-1 px-3 border border-white-300 text-sm hover:bg-red-200 hover:border-red-300 hover:text-white-100 flex items-center mr-3 mt-3"
          >
            <FiTrash className="mr-1" />
            Hapus
          </button>
        </div>
      </div>
    </div>
  );
}
