import { FiImage } from "react-icons/fi";
import { FaTrash, FaWeight } from "react-icons/fa";
import NumberFormat from "react-number-format";

export default function ProductList({
  title,
  imgSrc,
  price,
  onChangeImage = () => {},
  onChangeWeight = () => {},
  onDelete = () => {},
  id,
}) {
  return (
    <div className="border-b border-white-200 mt-5 pb-5 flex">
      <img
        src={process.env.REACT_APP_BASE_URL + imgSrc}
        alt={"Gambar " + title}
        className="w-20 lg:w-24 h-20 lg:h-24 object-cover"
      />
      <div className="flex-1 ml-5">
        <h3 className="font-bold text-base md:text-lg">{title}</h3>
        <div className="flex flex-wrap mt-1">
          <span className="flex items-center text-sm mr-5">
            <NumberFormat
              prefix="Rp"
              thousandSeparator={true}
              value={price}
              displayType="text"
            />
          </span>
        </div>
        <div className="flex flex-wrap">
          <button
            onClick={() => {
              onChangeImage(id);
            }}
            type="button"
            className="py-1 px-3 border border-white-300 text-sm hover:bg-green-200 hover:border-green-300 hover:text-white-100 flex items-center mr-3 mt-3"
          >
            <FiImage className="mr-1" />
            Ganti Gambar
          </button>
          <button
            onClick={() => {
              onChangeWeight(id);
            }}
            type="button"
            className="py-1 px-3 border border-white-300 text-sm hover:bg-green-200 hover:border-green-300 hover:text-white-100 flex items-center mr-3 mt-3"
          >
            <FaWeight className="mr-1" />
            Atur Berat
          </button>
          <button
            onClick={() => {
              onDelete(id);
            }}
            type="button"
            className="py-1 px-3 border border-white-300 text-sm hover:bg-red-200 hover:border-red-300 hover:text-white-100 flex items-center mr-3 mt-3"
          >
            <FaTrash className="mr-1" />
            Hapus
          </button>
        </div>
      </div>
    </div>
  );
}
