const { forwardRef } = require("react");

const Textarea = forwardRef(
  ({ containerClassName, className, label, message, ...props }, ref) => {
    return (
      <div className={containerClassName}>
        {label && <label className="text-black-200 font-bold">{label}</label>}
        <div className="border border-white-300 w-full flex rounded-sm">
          <textarea
            ref={ref}
            className={"flex-1 p-2 rounded-sm focus:outline-none " + className}
            {...props}
          />
        </div>

        {message && <span className="text-red-200 text-sm">{message}</span>}
      </div>
    );
  }
);

export default Textarea;
