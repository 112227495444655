import { useEffect, useState } from "react";
import {
  FiArchive,
  FiBarChart,
  FiBook,
  FiBox,
  FiCreditCard,
  FiFeather,
  FiHome,
  FiList,
  FiLogOut,
  FiMenu,
  FiSettings,
  FiUsers,
  FiX,
} from "react-icons/fi";
import { useSelector } from "react-redux";
import service from "../service";
import * as Sidebar from "./Sidebar";
import { useCookies } from "react-cookie";
import BigLoader from "./BigLoader";

export default function Layout({ children }) {
  const [menuShown, setMenuShown] = useState(false);
  const {
    interface: { rightHeader: RightHeader, ...web },
  } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const removeCookie = useCookies()[2];
  const { role = "super" } = useCookies()[0];
  const [sidemenu, setSidemenu] = useState([]);

  const _logout = () => {
    setLoading(true);
    service
      .delete("/api/auth/logout")
      .then(() => {
        setLoading(false);
        service.defaults.headers.common["Authorization"] = undefined;
        removeCookie("id", {
          path: "/",
        });
        removeCookie("username", {
          path: "/",
        });
        removeCookie("token", {
          path: "/",
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (role === "super") {
      setSidemenu([
        {
          name: "Dashboard",
          path: "/",
          icon: FiHome,
        },
        {
          name: "Statistik",
          dropdown: true,
          icon: FiBarChart,
          dropdown: [
            {
              title: "User Aktif",
              to: "/stats/active-user",
            },
            {
              title: "Data Baca User",
              to: "/stats/book",
            },
            {
              title: "Buku",
              to: "/stats/category",
            },
          ],
        },
        {
          name: "Kategori",
          path: "/category",
          icon: FiArchive,
        },
        {
          name: "Konten",
          path: "/content",
          icon: FiBook,
        },
        {
          name: "Produk",
          path: "/product",
          icon: FiArchive,
        },
        {
          name: "Online Course",
          path: "/course",
          icon: FiFeather,
        },
        {
          name: "Partner",
          path: "/partner",
          icon: FiUsers,
        },
        {
          name: "Bank",
          path: "/bank",
          icon: FiCreditCard,
        },
        {
          name: "Pesanan",
          path: "/order",
          icon: FiList,
        },
        {
          name: "Pengaturan",
          path: "/config",
          icon: FiSettings,
        },
        {
          name: "Rajaongkir",
          path: "/rajaongkir",
          icon: FiBox,
        },
      ]);
    } else if (role === "partner") {
      setSidemenu([
        {
          name: "Dashboard",
          path: "/",
          icon: FiHome,
        },
        {
          name: "Statistik",
          dropdown: true,
          icon: FiBarChart,
          dropdown: [
            {
              title: "User Aktif",
              to: "/stats/active-user",
            },
            {
              title: "Data Baca User",
              to: "/stats/book",
            },
            {
              title: "Buku",
              to: "/stats/category",
            },
          ],
        },
      ]);
    }
  }, [role]);
  return (
    <div className="h-screen overflow-auto flex bg-background">
      {menuShown && (
        <div
          className="bg-black-100 opacity-50 position fixed top-0 left-0 w-full h-screen block lg:hidden z-30"
          onClick={() => {
            setMenuShown(false);
          }}
        ></div>
      )}
      <div
        className={
          "w-11/12 md:w-64 bg-white-100 h-full overflow-auto flex flex-col border-r border-white-300 fixed lg:static top-0 left-0 z-30 transform transition-all duration-300 " +
          (menuShown ? "-translate-x-0" : "-translate-x-full") +
          " lg:translate-x-0"
        }
      >
        <div className="mb-5 h-16 border-b border-white-200 flex items-center">
          <h1 className="text-xl montserrat-alternates p-5 font-bold flex-1 text-center">
            Admin Panel
          </h1>
          <button
            className="w-8 h-8 mr-3 flex lg:hidden justify-center items-center text-black-400"
            type="button"
            onClick={() => {
              setMenuShown(false);
            }}
          >
            <FiX />
          </button>
        </div>
        <Sidebar.Wrapper>
          {sidemenu.map((item, index) =>
            item.dropdown ? (
              <Sidebar.DropdownList
                key={`menu-${index}`}
                icon={item.icon}
                active={web.active.split("/")[0] === item.name}
                dropdownActive={
                  web.active.split("/").length > 1
                    ? web.active.split("/")[1]
                    : null
                }
                dropdown={item.dropdown}
                dropdownOnClick={() => {
                  setMenuShown(false);
                }}
              >
                {item.name}
              </Sidebar.DropdownList>
            ) : (
              <Sidebar.List
                onClick={() => {
                  setMenuShown(false);
                }}
                key={`menu-${index}`}
                to={item.path}
                icon={item.icon}
                active={web.active === item.name}
              >
                {item.name}
              </Sidebar.List>
            )
          )}
        </Sidebar.Wrapper>
        <div className="pl-5 mb-2">
          <button
            onClick={() => _logout()}
            type="button"
            className="rounded-l-md pr-5 py-3 monda pl-3 w-full flex items-center text-black-400 hover:bg-primary-100"
          >
            <span className="w-8 text-center">
              <FiLogOut />
            </span>
            Keluar
          </button>
        </div>
      </div>
      <div className="flex-1 overflow-auto h-screen">
        <div className="h-16 bg-primary-500 lg:bg-white-100 border-b border-primary-600 lg:border-white-200 flex items-center p-5 sticky top-0 z-20">
          <button
            className="w-8 h-8 mr-3 flex lg:hidden justify-center items-center text-white-100"
            type="button"
            onClick={() => {
              setMenuShown(true);
            }}
          >
            <FiMenu />
          </button>
          <h1 className="font-bold text-lg mr-auto text-white-100 lg:text-black-200">
            {web.pageTitle}
          </h1>
        </div>
        <div className="p-5">{children}</div>
      </div>
      {loading && <BigLoader />}
    </div>
  );
}
