import { useCallback } from "react";

function debounce(callback = () => {}, seconds = 1000) {
  let timeout;

  return (value) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      callback(value);
    }, seconds);
  };
}

const useDebounce = (...props) => useCallback(debounce(...props), []);

export default useDebounce;
