import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { Link } from "react-router-dom";

export const Wrapper = ({ children }) => {
  return <ul className="flex flex-col flex-1 overflow-auto">{children}</ul>;
};

export const List = ({ children, className, active, icon, ...props }) => {
  const Icon = icon;
  return (
    <li className={"pl-5 mb-2"}>
      <Link
        className={
          "rounded-l-md pr-5 py-3 monda pl-3 flex items-center " +
          (active
            ? "bg-primary-500 text-white-100"
            : "text-black-400 hover:bg-primary-100")
        }
        {...props}
      >
        <span className="w-8 text-center">
          <Icon />
        </span>
        {children}
      </Link>
    </li>
  );
};

export const DropdownList = ({
  children,
  className,
  active,
  icon,
  onClick = () => {},
  dropdown = [],
  dropdownActive,
  dropdownOnClick = () => {},
  ...props
}) => {
  const Icon = icon;
  const [show, setShow] = useState(active);
  return (
    <li className={"pl-5 mb-2"}>
      <button
        onClick={(e) => {
          setShow((value) => !value);
          onClick(e);
        }}
        className={
          "rounded-l-md px-3 py-3 monda flex items-center w-full" +
          (!active
            ? " hover:bg-primary-100"
            : !show
            ? " bg-primary-500 text-white-100"
            : "")
        }
        type="button"
        {...props}
      >
        <span className="w-8 text-center">
          <Icon />
        </span>
        {children}
        <FiChevronDown
          className={`ml-auto transition duration-300 transform ${
            show ? "rotate-0" : "-rotate-90"
          }`}
        />
      </button>
      <ul
        className={`ml-5 rounded-bl-md border-l border-white-300 transition-all duration-300 overflow-hidden ${
          show ? "max-h-full" : "max-h-0"
        }`}
      >
        {dropdown.map((item, index) => (
          <li className="mt-2 ml-3" key={`menu-${index}`}>
            <Link
              className={
                "block pl-3 pr-3 py-3 rounded-l-md " +
                (dropdownActive === item.title
                  ? "bg-primary-500 text-white-100"
                  : "text-black-400 hover:bg-primary-100")
              }
              to={item.to}
              onClick={() => {
                dropdownOnClick(index);
              }}
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};
