import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { BrowserRouter, Route, Switch, useRouteMatch } from "react-router-dom";
import Auth from "./components/route/Auth";
import Private from "./components/route/Private";
import Bab from "./pages/contents/babs/Bab";
import ComposeBab from "./pages/contents/babs/ComposeBab";
import ComposeContent from "./pages/contents/ComposeContent";
import Content from "./pages/contents/Content";
import Dashboard from "./pages/dashboards/Dashboard";
import Login from "./pages/Login";
import Partner from "./pages/partners/Partner";
import service from "./service";
import Layout from "./components/Layout";
import UserDetail from "./pages/UserDetail";
import ActiveUser from "./pages/stats/ActiveUser";
import TopBook from "./pages/stats/TopBook";
import TopCategory from "./pages/stats/TopCategory";
import Course from "./pages/course/Course";
import Category from "./pages/category/Category";
import Subject from "./pages/course/subject/Subject";
import ComposeSubject from "./pages/course/subject/ComposeSubject";
import Product from "./pages/product/Product";
import Bank from "./pages/banks/Bank";
import Rajaongkir from "./pages/rajaongkir/Rajaongkir";
import Order from "./pages/order/Order";
import Config from "./pages/config/Config";

const ContentGroup = () => {
  return (
    <Switch>
      <Route path="/content/" exact>
        <Content />
      </Route>
      <Route path="/content/edit/:id">
        <ComposeContent editMode={true} />
      </Route>
      <Route path="/content/bab/:id">
        <ComposeBab editMode={true} />
      </Route>
      <Route path="/content/add">
        <ComposeContent />
      </Route>
      <Route path="/content/:id/bab/add">
        <ComposeBab />
      </Route>
      <Route path="/content/:id/bab">
        <Bab />
      </Route>
    </Switch>
  );
};

const StatsGroup = () => {
  return (
    <Switch>
      <Route path="/stats/active-user">
        <ActiveUser />
      </Route>
      <Route path="/stats/book">
        <TopBook />
      </Route>
      <Route path="/stats/category">
        <TopCategory />
      </Route>
    </Switch>
  );
};

const CourseGroup = () => {
  return (
    <Switch>
      <Route path="/course/" exact>
        <Course />
      </Route>
      <Route path="/course/:id/subject/add">
        <ComposeSubject />
      </Route>
      <Route path="/course/:parent/subject/:id/edit">
        <ComposeSubject editMode={true} />
      </Route>
      <Route path="/course/:parent/subject/:id/add">
        <ComposeSubject inParent={true} />
      </Route>
      <Route path="/course/:id/subject">
        <Subject />
      </Route>
    </Switch>
  );
};

const PrivateGroup = () => {
  return (
    <Layout>
      <Switch>
        <Private path="/product" role={["super"]}>
          <Product />
        </Private>
        <Private path="/category" role={["super"]}>
          <Category />
        </Private>
        <Private path="/content" role={["super"]}>
          <ContentGroup />
        </Private>
        <Private path="/stats" role={["super", "partner"]}>
          <StatsGroup />
        </Private>
        <Private path="/bank" role={["super"]}>
          <Bank />
        </Private>
        <Private path="/rajaongkir" role={["super"]}>
          <Rajaongkir />
        </Private>
        <Private path="/order" role={["super"]}>
          <Order />
        </Private>
        <Private path="/config" role={["super"]}>
          <Config />
        </Private>
        <Private path="/partner" role={["super"]}>
          <Partner />
        </Private>
        <Private path="/course" role={["super"]}>
          <CourseGroup />
        </Private>
        <Private path="/read-logs/:id" role={["super", "partner"]}>
          <UserDetail />
        </Private>
        <Private path="/" exact>
          <Dashboard />
        </Private>
      </Switch>
    </Layout>
  );
};

export default function App() {
  const [cookies] = useCookies();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (cookies.token) {
      service.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${cookies.token}`;
    }

    setLoading(false);
  }, []);

  if (loading) return null;
  return (
    <BrowserRouter>
      <Switch>
        <Auth path="/login">
          <Login />
        </Auth>
        <Route path="/">
          <PrivateGroup />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
