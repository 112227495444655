import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Route, useHistory, useLocation } from "react-router-dom";

export default function Auth({ children, ...props }) {
  const [cookies] = useCookies();
  const location = useLocation();
  const history = useHistory();

  let { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (cookies.token) {
      history.replace(from);
    }
  }, [cookies.token, history, from]);
  return <Route {...props}>{children}</Route>;
}
