import { Fragment } from "react";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import Loader from "../../components/Loader";
import MostReadList from "../../components/MostReadList";
import Table, { Td, Th } from "../../components/Table";
import service from "../../service";

const parsingChart = (data) => {
  const label = [];
  const value = [];
  for (let key in data) {
    label.push(key);
    value.push(data[key]);
  }

  return {
    label,
    value,
  };
};

export default function MainStat() {
  const [dataUserLogin, setDataUserLogin] = useState({});
  const [dataMostActiveUser, setDataMostActiveUser] = useState([]);
  const [dataMostReadBook, setDataMostReadBook] = useState([]);
  const [dataMostCategory, setDataMostCategory] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    let getUserLogin, getMostActiveUser, getMostReadBook, getMostCategory;
    try {
      getUserLogin = (await service.get("/api/stat/daily-user-login")).data;
      getMostActiveUser = (await service.get("/api/stat/monthly-active-user"))
        .data;
      getMostReadBook = (await service.get("/api/stat/most-read-book")).data;
      getMostCategory = (await service.get("/api/stat/most-read-category"))
        .data;
    } catch (e) {
      setLoading(false);
      return;
    }

    setDataUserLogin(parsingChart(getUserLogin));
    setDataMostActiveUser(getMostActiveUser.data);
    setDataMostReadBook(getMostReadBook.data);
    setDataMostCategory(getMostCategory.data);

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <div className="h-64 flex justify-center items-center flex-col">
        <Loader className="bg-black-100" />
        <span className="mt-3">Memuat</span>
      </div>
    );
  }
  return (
    <Fragment>
      <Card>
        <Line
          data={{
            labels: dataUserLogin.label,
            datasets: [
              {
                label: "Jumlah User Login",
                data: dataUserLogin.value,
                fill: false,
                backgroundColor: "rgb(255, 99, 132)",
                borderColor: "rgba(255, 99, 132, 0.2)",
              },
            ],
          }}
          className="h-full"
          width={320}
        />
      </Card>
      <div className="flex flex-col lg:flex-row mt-5">
        <div className="flex-1 mr-0 mb-5 lg:mr-8 lg:mb-0">
          <Card>
            <h2 className="font-bold text-lg mr-auto text-black-200 mb-5">
              Pengguna Aktif Bulan Ini
            </h2>
            <Table>
              <thead>
                <tr>
                  <Th>NO</Th>
                  <Th>NAMA</Th>
                  <Th>TOTAL</Th>
                </tr>
              </thead>
              <tbody>
                {dataMostActiveUser.map((value, index) => (
                  <tr key={`act-${index}`}>
                    <Td className="text-center">{index + 1}</Td>
                    <Td>
                      <Link to={`/read-logs/${value.id}`}>
                        {value.fullname}
                      </Link>
                    </Td>
                    <Td className="text-center">{value.total}</Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </div>
        <div className="w-full lg:w-1/3">
          <Card className="mb-5">
            <h2 className="font-bold text-lg mr-auto text-black-200 mb-5">
              Buku Teratas
            </h2>
            {dataMostReadBook.map((value, index) => (
              <MostReadList
                title={value.title}
                total={value.read || 0}
                key={`rb-${index}`}
              />
            ))}
          </Card>
          <Card>
            <h2 className="font-bold text-lg mr-auto text-black-200 mb-5">
              Kategori Teratas
            </h2>
            {dataMostCategory.map((value, index) => (
              <MostReadList
                title={value.name}
                total={value.read || 0}
                key={`c-${index}`}
              />
            ))}
          </Card>
        </div>
      </div>
    </Fragment>
  );
}
