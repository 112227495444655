import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { setInterface } from "../../redux/slices/interfaceSlice";
import MainStat from "./MainStat";

export default function Dashboard() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setInterface({ active: "Dashboard", pageTitle: "Dashboard" }));
  }, [dispatch]);
  return (
    <Fragment>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <MainStat />
    </Fragment>
  );
}
