import swal from "@sweetalert/with-react";
import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { FiUser } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import BigLoader from "../../components/BigLoader";
import Button from "../../components/Button";
import Card from "../../components/Card";
import CourseList from "../../components/CourseList";
import Dropzone from "../../components/Dropzone";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import useDebounce from "../../debounce";
import { setInterface } from "../../redux/slices/interfaceSlice";
import service from "../../service";

export default function Course() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(true);
  const [postLoading, setPostLoading] = useState(false);
  const _modalCover = useRef();
  const _modalMentor = useRef();
  const { control, handleSubmit, watch, reset } = useForm();
  const [id, setId] = useState(0);
  const debounce = useDebounce((text) => getUser(text));

  const changeCover = ({ cover }) => {
    setPostLoading(true);

    const formData = new FormData();
    formData.append("cover", cover);

    service
      .put(`/api/course/${id}/cover`, formData)
      .then(() => {
        _modalCover.current.hide();
        setPostLoading(false);
        getData();
        swal("Berhasil", `Cover berhasil diganti`, "success");
      })
      .catch(() => {
        setPostLoading(false);
      });
  };

  const deleteMentor = (id, mentor) => {
    swal({
      title: "Anda yakin?",
      text: "Anda akan menghapus mentor ini",
      icon: "warning",
      buttons: ["Batal", true],
      dangerMode: true,
      cancelButton: "Batal",
    }).then((value) => {
      if (value) {
        setPostLoading(true);
        service
          .delete(`/api/course/${id}/mentor/${mentor}`)
          .then(() => {
            setPostLoading(false);
            getData();
            swal("Berhasil", `Mentor berhasil dihapus`, "success");
          })
          .catch(() => {
            setPostLoading(false);
          });
      }
    });
  };

  const addMentor = (mentor) => {
    setPostLoading(true);
    service
      .post(`/api/course/${id}/mentor`, {
        mentor,
      })
      .then(() => {
        _modalMentor.current.hide();
        setPostLoading(false);
        getData();
        swal("Berhasil", `Mentor berhasil diganti`, "success");
      })
      .catch(() => {
        setPostLoading(false);
      });
  };

  const getUser = (q = "") => {
    service
      .get("/api/user", {
        params: {
          q,
          mentor: true,
        },
      })
      .then((response) => {
        setUserLoading(false);
        setUsers(response.data);
      })
      .catch(() => {
        setUserLoading(false);
        setUsers([]);
      });
  };

  const getData = () => {
    setGetLoading(true);
    service
      .get("/api/course", {
        params: {
          page,
        },
      })
      .then((response) => {
        setGetLoading(false);
        setData(response.data.data);
        setTotal(response.data.total);
      })
      .catch((e) => {
        setGetLoading(false);
      });
  };

  const onDelete = (id) => {
    swal({
      title: "Anda yakin?",
      text: "Anda akan menghapus course ini",
      icon: "warning",
      buttons: ["Batal", true],
      dangerMode: true,
      cancelButton: "Batal",
    }).then((value) => {
      if (value) {
        setPostLoading(true);
        service
          .delete(`/api/course/${id}`)
          .then(() => {
            getData();
            setPostLoading(false);
            swal("Berhasil", `Course berhasil dihapus`, "success");
          })
          .catch((e) => {
            setPostLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    dispatch(
      setInterface({ active: "Online Course", pageTitle: "Online Course" })
    );
  }, [dispatch]);
  return (
    <Fragment>
      <Helmet>
        <title>Online Course</title>
      </Helmet>
      <Card>
        {getLoading ? (
          <div className="h-64 flex justify-center items-center flex-col">
            <Loader className="bg-black-100" />
            <span className="mt-3">Memuat</span>
          </div>
        ) : (
          <Fragment>
            {data.map((item, index) => (
              <CourseList
                id={item.id}
                key={`${index}`}
                title={item.title}
                mentor={item.users}
                imgSrc={item.cover}
                onDelete={() => {
                  onDelete(item.id);
                }}
                onChangeCover={() => {
                  setId(item.id);
                  reset({ cover: null });
                  _modalCover.current.show();
                }}
                onAddMentor={() => {
                  setId(item.id);
                  _modalMentor.current.show();
                }}
                onDeleteMentor={(id) => deleteMentor(item.id, id)}
              />
            ))}
            <ReactPaginate
              pageCount={total}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              initialPage={page - 1}
              containerClassName="flex flex-wrap mt-8"
              previousLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
              nextLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
              pageLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
              activeLinkClassName="bg-primary-500 border-primary-600 text-white-100"
              onPageChange={({ selected }) => setPage(selected + 1)}
            />
          </Fragment>
        )}
      </Card>
      <Modal title="Ganti Cover" ref={_modalCover}>
        <form onSubmit={handleSubmit(changeCover)}>
          <Controller
            name="cover"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <Dropzone
                containerClassName="mb-4"
                onChange={(accepted) => {
                  onChange(accepted[0]);
                }}
                dropzone={{ accept: "image/jpeg, image/png" }}
              />
            )}
          />
          {watch("cover") ? (
            <Fragment>
              <span className="font-bold">Preview :</span>
              <div className="flex justify-center mb-4">
                <img
                  src={URL.createObjectURL(watch("cover"))}
                  alt="Preview Cover"
                  className="w-28 h-28 object-cover"
                />
              </div>
            </Fragment>
          ) : null}
          <Button type="submit" color="blue" className="w-28">
            Simpan
          </Button>
        </form>
      </Modal>
      <Modal title="Pilih Mentor" ref={_modalMentor}>
        <TextInput
          containerClassName="mb-4"
          placeholder="Cari pengguna"
          onChange={(e) => {
            setUserLoading(true);
            debounce(e.target.value);
          }}
        />
        {userLoading ? (
          <div className="flex justify-center h-32">
            <Loader className="bg-black-200" />
          </div>
        ) : (
          users.map((item, index) => (
            <button
              type="button"
              onClick={() => {
                addMentor(item.id);
              }}
              key={`user-${index}`}
              className="w-full text-left py-3 border-b border-white-300 hover:bg-white-200 flex items-center"
            >
              <FiUser className="mx-5" size={24} />
              <div className="flex-1">
                <span className="font-bold">{item.fullname}</span>
                <br />
                <span className="text-sm">{item.username}</span>
              </div>
            </button>
          ))
        )}
      </Modal>
      {postLoading ? <BigLoader /> : null}
    </Fragment>
  );
}
