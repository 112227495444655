import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import Loader from "../../components/Loader";
import Table, { Td, Th } from "../../components/Table";
import TextInput from "../../components/TextInput";
import useDebounce from "../../debounce";
import { setInterface } from "../../redux/slices/interfaceSlice";
import service from "../../service";

export default function ActiveUser() {
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const _first = useRef(true);

  const debounce = useDebounce((value) => getData(value[0], value[1]));

  useEffect(() => {
    dispatch(
      setInterface({ active: "Statistik/User Aktif", pageTitle: "User Aktif" })
    );
  }, [dispatch]);

  const getData = (q, page) => {
    service
      .get("/api/stat/monthly-active-user", {
        params: {
          q,
          page,
        },
      })
      .then((response) => {
        _first.current = false;
        setLoading(false);
        setData(response.data.data);
        setTotal(response.data.total);
      })
      .catch(() => {
        setLoading(false);
        setData([]);
      });
  };

  useEffect(() => {
    if (!_first.current) {
      setLoading(true);
      debounce([q, page]);
    }
  }, [q]);

  useEffect(() => {
    setLoading(true);
    getData(q, page);
  }, [page]);

  return (
    <Fragment>
      <Helmet>
        <title>User Aktif</title>
      </Helmet>
      <Card>
        <div className="w-full md:w-1/2 lg:w-1/3 flex items-center">
          <TextInput
            placeholder="Cari disini..."
            onChange={(e) => {
              setQ(e.target.value);
            }}
          />
        </div>
        <div className="border-t border-white-300 pt-3 mt-3">
          {loading ? (
            <div className="h-64 flex justify-center items-center flex-col">
              <Loader className="bg-black-100" />
              <span className="mt-3">Memuat</span>
            </div>
          ) : (
            <Fragment>
              <Table>
                <thead>
                  <tr>
                    <Th className="w-20">NO</Th>
                    <Th>NAMA</Th>
                    <Th className="w-1/3">TERAKHIR LOGIN</Th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={`act-${index}`}>
                      <Td className="text-center">{index + 1}</Td>
                      <Td>
                        <Link to={`/read-logs/${item.id}`}>
                          {item.fullname}
                        </Link>
                      </Td>
                      <Td className="text-center">
                        {item.last_login
                          ? moment(item.last_login).format("DD-MM-YYYY HH:mm")
                          : ""}
                      </Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <ReactPaginate
                pageCount={total}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                initialPage={page - 1}
                containerClassName="flex flex-wrap mt-8"
                previousLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
                nextLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
                pageLinkClassName="m-1 border border-white-400 py-1 px-2 hover:bg-primary-500 hover:border-primary-600 hover:text-white-100 block"
                activeLinkClassName="bg-primary-500 border-primary-600 text-white-100"
                onPageChange={({ selected }) => setPage(selected + 1)}
              />
            </Fragment>
          )}
        </div>
      </Card>
    </Fragment>
  );
}
