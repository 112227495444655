import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Card from "../../components/Card";
import { setInterface } from "../../redux/slices/interfaceSlice";
import service from "../../service";
import ReactSelect from "react-select";
import swal from "@sweetalert/with-react";
import BigLoader from "../../components/BigLoader";
import Button from "../../components/Button";
import Textarea from "../../components/Textarea";

export default function Config() {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const { handleSubmit, register, reset, control } = useForm();
  const [loading, setLoading] = useState(false);

  const parsingSelect = (value = [], options = []) => {
    const values = [];
    for (let selected of value) {
      const option = options.find((el) => el.value === selected);
      if (option) {
        values.push(option);
      }
    }

    return values;
  };

  const _proceed = (data) => {
    setLoading(true);
    service
      .post("/api/config", data)
      .then(() => {
        setLoading(false);
        swal("Berhasil", "Pengaturan telah disimpan", "success");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getConfig = () => {
    service
      .get("/api/config")
      .then((response) => {
        const { stickyCategory, paymentText } = response.data;

        reset({
          stickyCategory,
          paymentText,
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    dispatch(setInterface({ active: "Pengaturan", pageTitle: "Pengaturan" }));
  }, [dispatch]);

  useEffect(() => {
    service
      .get("/api/category")
      .then((response) => {
        const data = [];
        for (let category of response.data) {
          data.push({
            value: category.id,
            label: category.name,
          });
        }

        setCategories(data);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    getConfig();
  }, [categories]);

  return (
    <Fragment>
      <Helmet>
        <title>Pengaturan</title>
      </Helmet>
      <Card>
        <form className="w-full lg:w-1/2" onSubmit={handleSubmit(_proceed)}>
          <div className="mb-4">
            <label className="text-black-200 font-bold">Sticky Kategori</label>
            <Controller
              name="stickyCategory"
              control={control}
              render={({ field: { value, onChange } }) => (
                <ReactSelect
                  isMulti
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: 0,
                      // This line disable the blue border
                      boxShadow: "none",
                    }),
                  }}
                  value={parsingSelect(value, categories)}
                  onChange={(inputValue) => {
                    const values = [];
                    for (let value of inputValue) {
                      values.push(value.value);
                    }

                    onChange(values);
                  }}
                  options={categories}
                  classNamePrefix="react-select"
                  className="react-select-container mb-4"
                />
              )}
            />
          </div>
          <Textarea
            type="text"
            label="Teks Pembayaran"
            containerClassName="mb-4"
            rows={8}
            {...register("paymentText")}
          />
          <Button type="submit" className="w-24">
            Simpan
          </Button>
        </form>
      </Card>
      {loading && <BigLoader />}
    </Fragment>
  );
}
