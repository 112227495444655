import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FiBookOpen, FiClock } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import BabList from "../../../components/BabList";
import Card from "../../../components/Card";
import Loader from "../../../components/Loader";
import { setInterface } from "../../../redux/slices/interfaceSlice";
import service from "../../../service";
import BigLoader from "../../../components/BigLoader";
import swal from "@sweetalert/with-react";

export default function Bab() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingContent, setLoadingContent] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [data, setData] = useState({});

  const getData = () => {
    return new Promise((resolve, reject) => {
      service
        .get("/api/content/" + id + "/detail")
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          if (e.response?.status === 404) {
            history.replace("/content");
          }
          reject(e);
        });
    });
  };

  const onDelete = (id) => {
    swal({
      title: "Anda yakin?",
      text: "Anda akan menghapus ring ini",
      icon: "warning",
      buttons: ["Batal", true],
      dangerMode: true,
      cancelButton: "Batal",
    }).then((value) => {
      if (value) {
        setLoadingDelete(true);
        service.delete(`/api/bab/${id}`).then(() => {
          getData()
            .then((response) => {
              setData(response);
              setLoadingDelete(false);
              swal("Berhasil", "Ring telah dihapus", "success");
            })
            .catch((e) => {
              setLoadingDelete(false);
            });
        });
      }
    });
  };

  useEffect(() => {
    dispatch(
      setInterface({
        active: "Konten",
        pageTitle: "Kelola Ring",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getData()
      .then((response) => {
        setLoadingContent(false);
        setData(response);
      })
      .catch((e) => {
        setLoadingContent(false);
      });
  }, [id]);

  if (loadingContent) {
    return (
      <div className="h-64 flex justify-center items-center flex-col">
        <Helmet>
          <title>Kelola Ring</title>
        </Helmet>
        <Loader className="bg-black-100" />
        <span className="mt-3">Memuat</span>
      </div>
    );
  }

  return (
    <Fragment>
      <Card>
        <div className="flex flex-col items-center">
          <img
            src={process.env.REACT_APP_BASE_URL + data.cover}
            className="w-32 h-32 object-cover"
            alt="Cover"
          />
          <div className="flex-1 mt-5">
            <h1 className="font-bold text-base lg:text-lg text-center">
              {data.title}
            </h1>
            <div className="flex flex-wrap mt-1 justify-center">
              <span className="flex items-center text-sm mr-5">
                <FiClock className="mr-2" />{" "}
                {moment(data.created_at).format("DD/MM/YYYY")}
              </span>
              <span className="flex items-center text-sm mr-5">
                <FiBookOpen className="mr-2" /> {data.babs?.length} Ring
              </span>
            </div>
          </div>
        </div>
      </Card>
      <Card className="mt-5">
        <div className="flex items-center mb-8">
          <h3 className="text-lg lg:text-xl font-bold monda flex-1">
            Daftar Ring
          </h3>
          <Link
            className="font-bold text-primary-500"
            to={`/content/${id}/bab/add`}
          >
            + Tambah Baru
          </Link>
        </div>
        {data.babs?.length > 0 ? (
          <div className="border-l-2 border-white-400">
            {data.babs.map((item, index) => (
              <BabList
                title={item.title}
                key={`${index}`}
                id={item.id}
                onDelete={onDelete}
              />
            ))}
          </div>
        ) : (
          <div className="text-center flex flex-col justify-center items-center h-40">
            <p>Belum ada ring pada buku ini</p>
          </div>
        )}
      </Card>
      {loadingDelete && <BigLoader />}
    </Fragment>
  );
}
