import { FaTrash } from "react-icons/fa";
import { FiCamera, FiList, FiUser, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function CourseList({
  id,
  title,
  mentor = [],
  imgSrc,
  onChangeCover = () => {},
  onAddMentor = () => {},
  onDeleteMentor = () => {},
  onDelete = () => {},
}) {
  return (
    <div className="border-b border-white-200 mt-5 pb-5 flex">
      <img
        src={process.env.REACT_APP_BASE_URL + imgSrc}
        alt={"Cover " + title}
        className="w-20 lg:w-28 h-20 lg:h-28 object-cover"
      />
      <div className="flex-1 ml-5">
        <h3 className="font-bold text-base md:text-lg">{title}</h3>
        {mentor.length ? (
          <div className="flex flex-wrap">
            {mentor.map((item, index) => (
              <div
                key={`${index}`}
                className="text-sm bg-white-200 px-2 flex mb-3 mr-2 h-8 items-center rounded"
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "clip",
                  }}
                >
                  {item.fullname}
                </span>
                <button
                  className="w-5 h-5 bg-red-100 hover:bg-red-200 text-white-100 flex justify-center items-center rounded ml-2"
                  type="button"
                  onClick={() => onDeleteMentor(item.id)}
                >
                  <FiX />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="mt-1 text-sm mb-2 mr-2 h-8">Belum Ada</div>
        )}
        <div className="flex flex-wrap">
          <button
            onClick={() => {
              onChangeCover();
            }}
            type="button"
            className="py-1 px-3 border border-white-300 text-sm hover:bg-primary-400 hover:border-primary-500 hover:text-white-100 flex items-center mr-3 mb-3"
          >
            <FiCamera className="mr-1" />
            Ganti Cover
          </button>
          <button
            onClick={() => {
              onAddMentor();
            }}
            type="button"
            className="py-1 px-3 border border-white-300 text-sm hover:bg-primary-400 hover:border-primary-500 hover:text-white-100 flex items-center mr-3 mb-3"
          >
            <FiUser className="mr-1" />
            Tambah Mentor
          </button>

          <Link
            to={`/course/${id}/subject`}
            className="py-1 px-3 border border-white-300 text-sm hover:bg-primary-400 hover:border-primary-500 hover:text-white-100 flex items-center mr-3 mb-3"
          >
            <FiList className="mr-1" />
            Materi
          </Link>
          <button
            onClick={() => {
              onDelete(id);
            }}
            type="button"
            className="py-1 px-3 border border-white-300 text-sm hover:bg-red-200 hover:border-red-300 hover:text-white-100 flex items-center mr-3 mb-3"
          >
            <FaTrash className="mr-1" />
            Hapus
          </button>
        </div>
      </div>
    </div>
  );
}
