import { FiEdit2, FiTrash } from "react-icons/fi";

export default function PartnerList({ name, id, onEdit, onDelete }) {
  return (
    <div className="p-3 border-b border-white-200">
      <h3 className="font-bold">{name}</h3>
      <div className="flex flex-wrap">
        <button
          type="button"
          onClick={() => onEdit(id)}
          className="py-1 px-3 border border-white-300 text-sm hover:bg-yellow-200 hover:border-yellow-300 flex items-center mr-3 mt-1"
        >
          <FiEdit2 className="mr-1" />
          Edit
        </button>
        <button
          type="button"
          onClick={() => onDelete(id)}
          className="py-1 px-3 border border-white-300 text-sm hover:bg-red-200 hover:border-red-300 hover:text-white-100 flex items-center mr-3 mt-1"
        >
          <FiTrash className="mr-1" />
          Hapus
        </button>
      </div>
    </div>
  );
}
