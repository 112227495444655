import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Route, useHistory, useLocation } from "react-router-dom";

export default function Private({ children, role = [], ...props }) {
  const [cookies] = useCookies();
  const history = useHistory();
  const location = useLocation();

  let state = { from: { pathname: location.pathname || "/" } };

  useEffect(() => {
    if (!cookies.token) {
      history.replace({ pathname: "/login", state });
    } else if (
      role.length > 0 &&
      !role.includes(cookies.role ? cookies.role : "super")
    ) {
      history.replace({ pathname: "/", state });
    }
  }, [cookies.token, cookies.role, history]);
  return <Route {...props}>{children}</Route>;
}
